import {FC, memo, useEffect} from "react";
import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import styled from "styled-components";
import {IChartProps} from "./types";

const Chart: FC<IChartProps> = memo((props) => {
  const {chartConfig, id} = props;

  useEffect(() => {
    highcharts3d(Highcharts);
    Highcharts.chart(id, chartConfig);
  }, [chartConfig, id]);

  return <ChartWrapper id={id} />;
});

const ChartWrapper = styled.div``;

export default Chart;
