import {memo} from "react";
import styled from "styled-components";
import {theme} from "../../config/theme";

const BlocksDivider = memo(() => {
  return (
    <BlocksDividerWrapper className={"blocks-divider_blocks-divider-wrapper"}>
      <BlocksDividerLineWrapper />
    </BlocksDividerWrapper>
  );
});

const BlocksDividerWrapper = styled.div`
  opacity: 0;
  position: relative;
  width: 100%;
  background: ${theme.colors.darkGrey};
`;

const BlocksDividerLineWrapper = styled.div`
  border-radius: 100%;
  height: 2px;
  width: 90%;
  margin-left: 5%;
  background: ${theme.colors.translucent.white(0.35)};
`;

export default BlocksDivider;
