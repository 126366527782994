import {memo} from "react";
import {toast} from "react-toastify";
import styled from "styled-components";
import Link from "../../../components/Link";
import {mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";
import Marquee from "react-fast-marquee";

const FooterBlock = memo(() => {
  return (
    <FooterBlockWrapper id={"footer-block_footer-block-wrapper"}>
      <Marquee autoFill={true}>
        <ImageWrapper src="/images/logos/logo-pretorian.png" alt="" />
        <ImageWrapper src="/images/logos/logo-eth.png" alt="" />
        <ImageWrapper src="/images/logos/logo-dexscreener.png" alt="" />
        <ImageWrapper src="/images/logos/logo-dextools.png" alt="" />
        <ImageWrapper src="/images/logos/logo-openai.png" alt="" />
        <ImageWrapper src="/images/logos/logo-c3.png" alt="" />
        <ImageWrapper src="/images/logos/logo-anduril.png" alt="" />
        <ImageWrapper src="/images/logos/logo-anthropic.png" alt="" />
      </Marquee>
      <LinksWrapper>
        <LinksColumnWrapper>
          <LinksColumnTitleWrapper>Socials</LinksColumnTitleWrapper>
          <Link
            href="https://t.me/+YFgBwmfrvuliMWEy"
            color={theme.colors.translucent.white(0.8)}
            fontFamily={theme.fonts.roboto.light}
          >
            Telegram
          </Link>
          <Link
            href="https://x.com/pretorianai?s=21"
            color={theme.colors.translucent.white(0.8)}
            fontFamily={theme.fonts.roboto.light}
          >
            Twitter
          </Link>
        </LinksColumnWrapper>
        <LinksColumnWrapper>
          <LinksColumnTitleWrapper>Our Projects</LinksColumnTitleWrapper>
          <Link
            href="https://hashhub.nl/"
            color={theme.colors.translucent.white(0.8)}
            fontFamily={theme.fonts.roboto.light}
          >
            HashHub
          </Link>
        </LinksColumnWrapper>
        <LinksColumnWrapper>
          <LinksColumnTitleWrapper>Our Products</LinksColumnTitleWrapper>
          <Link
            href="https://t.me/PretorianAI_bot"
            color={theme.colors.translucent.white(0.8)}
            fontFamily={theme.fonts.roboto.light}
          >
            Bot
          </Link>
        </LinksColumnWrapper>
        <LinksColumnWrapper>
          <LinksColumnTitleWrapper>Other Links</LinksColumnTitleWrapper>
          <div onClick={() => toast.info("Coming soon.")}>
            <Link
              href=""
              color={theme.colors.translucent.white(0.8)}
              fontFamily={theme.fonts.roboto.light}
            >
              Medium
            </Link>
          </div>
          <Link
            href="https://whitepaper.pretorianecosystem.nl/pretorianbotai"
            color={theme.colors.translucent.white(0.8)}
            fontFamily={theme.fonts.roboto.light}
          >
            Whitepaper
          </Link>
          <div onClick={() => toast.info("Coming soon.")}>
            <Link
              href=""
              color={theme.colors.translucent.white(0.8)}
              fontFamily={theme.fonts.roboto.light}
            >
              Audit
            </Link>
          </div>
          <div onClick={() => toast.info("Coming soon.")}>
            <Link
              href=""
              color={theme.colors.translucent.white(0.8)}
              fontFamily={theme.fonts.roboto.light}
            >
              KYC
            </Link>
          </div>
        </LinksColumnWrapper>
      </LinksWrapper>
    </FooterBlockWrapper>
  );
});

const FooterBlockWrapper = styled.div`
  overflow-x: hidden;
  padding: 50px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: ${theme.colors.darkGrey};

  @media (max-width: ${mobileMaxWidthPx}) {
    padding: 50px 15px 20px 15px;
  }
`;

const ImageWrapper = styled.img`
  aspect-ratio: 1;
  width: 50px;
  height: 50px;
  margin-right: 50px;

  @media (max-width: ${mobileMaxWidthPx}) {
    width: 30px;
    height: 30px;
    margin-right: 30px;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 1.5em;
  flex-wrap: wrap;
  gap: 30px;
  color: ${theme.colors.translucent.white(0.9)};
`;

const LinksColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LinksColumnTitleWrapper = styled.div`
  font-size: 25px;
  margin-left: 10px;
  color: ${theme.colors.white};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 20px;
  }
`;

export default FooterBlock;
