import {ISourceOptions} from "@tsparticles/engine";
import {theme} from "../../../config/theme";

export const promoBlockParticlesOptions: ISourceOptions = {
  fpsLimit: 120,
  fullScreen: false,
  interactivity: {
    // detectsOn: "canvas",
    events: {
      onClick: {
        enable: true,
        mode: "repulse",
      },
      onHover: {
        enable: true,
        mode: ["grab", "bubble", "connect"],
      },
    },
    modes: {
      repulse: {
        distance: 150,
        duration: 1,
      },
    },
  },
  particles: {
    collisions: {
      enable: true,
      mode: "bounce",
      maxSpeed: 2,
    },
    color: {
      value: theme.colors.tiffany,
    },
    links: {
      color: theme.colors.tiffany,
      distance: 250,
      enable: true,
      opacity: 0.25,
      width: 1,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: {
        default: "bounce",
      },
      random: true,
      speed: 4,
      straight: false,
    },
    number: {
      density: {
        enable: true,
      },
      value: 250,
    },
    opacity: {
      value: 0.25,
    },
    shape: {
      type: "circle",
    },
    size: {
      value: {min: 1, max: 5},
    },
  },
};
