import {motion} from "framer-motion";
import {FC, memo} from "react";
import {theme} from "../../../config/theme";
import {drawLinkIcons} from "./consts";
import {ILinkIconProps} from "./types";

const LinkIcon: FC<ILinkIconProps> = memo((props) => {
  const {isHovered} = props;

  return (
    <motion.svg
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 200.000000 200.000000"
      preserveAspectRatio="xMidYMid meet"
      initial={false}
      animate={isHovered ? "visible" : "hidden"}
      style={{width: "100%", height: "100%"}}
    >
      <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)">
        <motion.path
          d="M1400 1980 c0 -6 99 -10 277 -10 l278 0 -580 -580 c-318 -318 -576
-583 -572 -587 4 -4 269 254 587 572 l580 580 0 -273 c0 -175 4 -272 10 -272
6 0 10 103 10 290 l0 290 -295 0 c-190 0 -295 -4 -295 -10z"
          fill="transparent"
          strokeWidth="200"
          stroke={theme.colors.tiffany}
          variants={drawLinkIcons}
        />
        <motion.path
          d="M82 1523 c-18 -9 -43 -30 -55 -47 l-22 -31 0 -675 0 -675 22 -31 c47
-66 23 -64 733 -64 710 0 686 -2 733 64 l22 31 0 675 0 675 -22 31 c-47 66
-23 64 -735 64 -572 0 -647 -2 -676 -17z m1355 -23 c65 -39 63 -11 63 -732 0
-630 -1 -654 -20 -685 -39 -65 -13 -63 -720 -63 -707 0 -681 -2 -720 63 -19
31 -20 55 -20 685 0 721 -2 693 63 732 31 19 55 20 677 20 622 0 646 -1 677
-20z"
          fill="transparent"
          strokeWidth="200"
          stroke={theme.colors.tiffany}
          variants={drawLinkIcons}
        />
      </g>
    </motion.svg>
  );
});

export default LinkIcon;
