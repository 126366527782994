import {FC, memo} from "react";
import {
  BioShapeItemDecoWrapper,
  BioShapeItemDescriptionWrapper,
  BioShapeItemExtraDescriptionWrapper,
  BioShapeItemImageWrapper,
  BioShapeItemMetaWrapper,
  BioShapeItemShapePathWrapper,
  BioShapeItemSvgWrapper,
  BioShapeItemTitleBlockWrapper,
  BioShapeItemTitleWrapper,
  BioShapeItemWrapper,
} from "../consts";
import {IBioShapeProps} from "../types";

const BioShape2: FC<IBioShapeProps> = memo((props) => {
  const {title, description, extraDescription} = props;

  return (
    <BioShapeItemWrapper
      className="bio-shape-item bio-shape-item--style-2"
      data-animation-path-duration="1500"
      data-animation-path-easing="easeOutElastic"
      data-morph-path="M 418.1,159.8 C 460.9,222.9 497,321.5 452.4,383.4 417.2,432.4 371.2,405.6 271.3,420.3 137.2,440 90.45,500.6 42.16,442.8 -9.572,381 86.33,289.1 117.7,215.5 144.3,153.4 145.7,54.21 212.7,36.25 290.3,15.36 373.9,94.6 418.1,159.8 Z"
      data-path-scaleY="1.1"
      data-image-scaleX="1.3"
      data-image-scaleY="1.3"
      data-animation-deco-duration="2000"
      data-animation-deco-delay="100"
      data-deco-rotate="-10"
    >
      <BioShapeItemSvgWrapper
        className="bio-shape-item__svg"
        width="500px"
        height="500px"
        viewBox="0 0 500 500"
      >
        <clipPath id="clipBioShape2">
          <BioShapeItemShapePathWrapper d="M 378.1,121.2 C 408.4,150 417.2,197.9 411,245.8 404.8,293.7 383.5,341.7 353.4,370.7 303.2,419.1 198.7,427.7 144.5,383.8 86.18,336.5 67.13,221.3 111.9,161 138.6,125 188.9,99.62 240.7,90.92 292.4,82.24 345.6,90.32 378.1,121.2 Z" />
        </clipPath>
        <BioShapeItemDecoWrapper className="bio-shape-item__deco">
          <use xlinkHref="#bioShapeDeco2" />
        </BioShapeItemDecoWrapper>
        <g clipPath="url(#clipBioShape2)">
          <BioShapeItemImageWrapper
            xlinkHref="/images/bioShapes/content.png"
            x="0"
            y="0"
            height="500px"
            width="500px"
          />
        </g>
      </BioShapeItemSvgWrapper>
      <BioShapeItemMetaWrapper className="bio-shape-item__meta">
        <BioShapeItemTitleBlockWrapper>
          <BioShapeItemTitleWrapper>{title}</BioShapeItemTitleWrapper>
        </BioShapeItemTitleBlockWrapper>
        <BioShapeItemDescriptionWrapper className="bio-shape-item__description">
          {description}
        </BioShapeItemDescriptionWrapper>
        <BioShapeItemExtraDescriptionWrapper className="bio-shape-item__extra-description">
          {extraDescription}
        </BioShapeItemExtraDescriptionWrapper>
      </BioShapeItemMetaWrapper>
    </BioShapeItemWrapper>
  );
});

export default BioShape2;
