import {theme} from "../../config/theme";

export const defaultIconTheme = {
  strokeColor: "",
  rotate: "0deg",
  padding: "2px",
  opacity: 1,
  hoverOpacity: 1,
  size: "20px",
  width: "20px",
  height: "20px",
  background: "",
  color: theme.colors.white,
  hoverColor: theme.colors.white,
  hoverBackground: theme.colors.transparent,
};
