import {css} from "styled-components";
import {theme} from "../config/theme";

export default css`
  .rc-tooltip-inner {
    background: none;
    border: none;
    color: inherit;
  }

  .tooltip-common {
    width: 220px;
    word-break: break-word;
    color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.md};
    border: 2px solid ${theme.colors.grey};
    font-size: ${theme.fonts.sizes.lg};
    background: ${theme.colors.grey};
  }

  .tooltip-hidden {
    display: none;
  }
`;
