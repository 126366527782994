import {memo} from "react";
import {BioShapeTextWrapper} from "../index";

const BioShape1Text = memo(() => {
  return (
    <BioShapeTextWrapper>
      Our launch bot introduces an innovative approach for Fair Launches, ensuring safety for
      investors and offering tangible advantages for developers. Investors gain early access without
      the concern of snipers, experiencing a seamless and straightforward process. This allows them
      the opportunity to become foundational holders of projects they are passionate about, with
      boundless investment possibilities. Conversely, developers benefit from a more stable
      distribution among genuine supporters. This enables them to raise additional funds for ongoing
      development and generate pre-launch excitement with the unique features of our bot.
    </BioShapeTextWrapper>
  );
});

export default BioShape1Text;
