import {memo, useEffect} from "react";
import {useMediaQuery} from "react-responsive";
import styled from "styled-components";
import {mobileMaxWidth, mobileMaxWidthPx} from "../../../../config/consts";
import {theme} from "../../../../config/theme";
import {blocksAppearDuration, loaderHideDuration} from "../../consts";
import anime from "animejs";
import {ILetterSpanProps} from "../types";
import {
  lettersGradientDuration,
  nameElementAppearDuration,
  nameWords,
  promoElementAnimationDuration,
  promoWords,
} from "./consts";

const Letters = memo(() => {
  const nameAppearDelay = loaderHideDuration + blocksAppearDuration;
  const promoAppearDelay = nameAppearDelay + nameElementAppearDuration * nameWords.length;

  const isMobile = useMediaQuery({maxWidth: mobileMaxWidth});

  useEffect(() => {
    setTimeout(() => {
      anime
        .timeline({loop: false})
        .add({
          targets: ".promo-block_name-letters-div",
          easing: "easeOutCirc",
          scale: [15, 1],
          opacity: [0, 1],
          duration: nameElementAppearDuration,
          delay: (element, index) => nameElementAppearDuration * index,
        })
        .add({
          targets: ".promo-block_buy-button-wrapper",
          easing: "easeOutCirc",
          scale: [15, 1],
          opacity: [0, 1],
          duration: nameElementAppearDuration,
        });
    }, nameAppearDelay);
  }, [nameAppearDelay]);

  useEffect(() => {
    const delayTotal = 3200; // общая задержка анимации букв с поправкой на их количество
    let timeoutSubtrahend = 1700; // чем больше delayTotal, тем больше нужна timeoutSubtrahend

    promoWords.forEach((word, wordIndex) => {
      setTimeout(() => {
        const delayBase = delayTotal - 50 * word.length;
        anime
          .timeline({loop: true})
          .add({
            targets: `.promo-block_promo-letters-span_${wordIndex}`,
            easing: "easeOutExpo",
            translateX: [40, 0],
            translateZ: 0,
            opacity: [0, 1],
            duration: promoElementAnimationDuration,
            delay: (element, index) => delayBase + 50 * index,
          })
          .add({
            targets: `.promo-block_promo-letters-span_${wordIndex}`,
            easing: "easeInExpo",
            translateX: [0, -30],
            opacity: [1, 0],
            duration: promoElementAnimationDuration,
            delay: (element, index) => delayBase + 50 * index,
          });
      }, promoAppearDelay - timeoutSubtrahend);

      timeoutSubtrahend -= 1000; // задержка между анимациями каждой строки
    });
  }, [promoAppearDelay]);

  return (
    <MovingLettersWrapper gap={isMobile ? 20 : 10}>
      <NameLettersWrapper className={"promo-block_name-letters-wrapper"}>
        {nameWords.map((word) => (
          <NameLettersDiv key={word} className={"promo-block_name-letters-div"}>
            {word.split("").map((letter, letterIndex) => (
              <NameLetterSpan key={letterIndex} letter={letter}>
                {letter}
              </NameLetterSpan>
            ))}
          </NameLettersDiv>
        ))}
      </NameLettersWrapper>
      <PromoLettersWrapper className={"promo-block_promo-letters-wrapper"}>
        {promoWords.map((word, wordIndex) => (
          <PromoLettersDiv key={word}>
            {word.split("").map((letter, letterIndex) => (
              <PromoLetterSpan
                key={letterIndex}
                className={`promo-block_promo-letters-span_${wordIndex}`}
                letter={letter}
              >
                {letter}
              </PromoLetterSpan>
            ))}
          </PromoLettersDiv>
        ))}
      </PromoLettersWrapper>
    </MovingLettersWrapper>
  );
});

const MovingLettersWrapper = styled.div<{gap: number}>`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  gap: ${({gap}) => gap}px;
  justify-content: center;

  @media (max-width: ${mobileMaxWidthPx}) {
    flex-direction: column;
    justify-content: center;
  }
`;

const LettersWrapper = styled.div`
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  cursor: default;
  user-select: none;
  text-align: center;
  justify-content: center;
  font-family: ${theme.fonts.roboto.black};

  @media (max-width: ${mobileMaxWidthPx}) {
    margin: unset;
  }
`;

const NameLettersWrapper = styled(LettersWrapper)`
  font-size: 7em;

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 2.6em;
  }
`;

const NameLettersDiv = styled.div`
  transform-origin: left;
  opacity: 0;
  letter-spacing: 0;
  overflow: hidden;
`;

const NameLetterSpan = styled.span<ILetterSpanProps>`
  display: inline-block;
  line-height: 1.5em;
  transition: color ${lettersGradientDuration}ms ease;
  text-shadow: 6px 4px 8px ${theme.colors.translucent.white(0.3)};
  color: ${theme.colors.translucent.white(0.8)};

  :not(:last-child) {
    margin-right: ${({letter}) => (letter === " " ? "50px" : "30px")};

    @media (max-width: ${mobileMaxWidthPx}) {
      margin-right: ${({letter}) => (letter === " " ? "20px" : "10px")};
    }
  }
`;

const PromoLettersWrapper = styled(LettersWrapper)`
  font-size: 2.5em;

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 1.2em;
  }
`;

const PromoLettersDiv = styled.div`
  letter-spacing: 0;
  overflow: hidden;
`;

const PromoLetterSpan = styled.span<ILetterSpanProps>`
  display: inline-block;
  opacity: 0;
  line-height: 1.5em;
  transition: color ${lettersGradientDuration}ms ease;
  text-shadow: 5px 3px 7px ${theme.colors.translucent.white(0.3)};
  color: ${theme.colors.translucent.white(0.8)};

  :not(:last-child) {
    margin-right: ${({letter}) => (letter === " " ? "25px" : "10px")};

    @media (max-width: ${mobileMaxWidthPx}) {
      margin-right: ${({letter}) => (letter === " " ? "13px" : "5px")};
    }
  }
`;

export default Letters;
