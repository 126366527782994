import Particles from "@tsparticles/react";
import React, {memo} from "react";
import {useMediaQuery} from "react-responsive";
import styled, {css} from "styled-components";
import {mobileMaxWidth, mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";
import {mainParticlesOptions} from "../TokenomicBlock/consts";
import RoadmapBlockContent from "./RoadmapBlockContent";

const RoadmapBlock = memo(() => {
  const isMobile = useMediaQuery({maxWidth: mobileMaxWidth});

  return (
    <RoadmapBlockWrapper id={"roadmap-block_roadmap-block-wrapper"} isMobile={isMobile}>
      {!isMobile && (
        <Particles
          options={mainParticlesOptions}
          id="roadmap-block-particles"
          className="particles"
        />
      )}
      <ContentWrapper>
        <BlockTitleWrapper>Pretorian premium</BlockTitleWrapper>
        <RoadmapBlockContent />
      </ContentWrapper>
    </RoadmapBlockWrapper>
  );
});

const RoadmapBlockWrapper = styled.div<{isMobile: boolean}>`
  overflow: hidden;
  padding: 40px 0;
  position: relative;
  background: ${({isMobile}) => (isMobile ? theme.colors.darkGrey : "")};
  ${({isMobile}) =>
    isMobile &&
    css`
      background-image: url("/images/mobile-background.png");
      background-size: auto;
    `}
`;

const ContentWrapper = styled.div`
  margin: 0 30px;

  @media (max-width: ${mobileMaxWidthPx}) {
    margin: 0 15px;
  }
`;

const BlockTitleWrapper = styled.div`
  text-transform: uppercase;
  font-size: 4em;
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
  color: ${theme.colors.white};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 2em;
  }
`;

export default RoadmapBlock;
