import Particles from "@tsparticles/react";
import React, {memo, useMemo} from "react";
import {useMediaQuery} from "react-responsive";
import styled, {css} from "styled-components";
import Chart from "../../../components/Chart";
import {desktopDefaultWidth, mobileMaxWidth, mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";
import {mainParticlesOptions, tokenomicChartConfig} from "./consts";

const TokenomicBlock = memo(() => {
  const isMobile = useMediaQuery({maxWidth: mobileMaxWidth});
  const isLargeScreen = useMediaQuery({minWidth: desktopDefaultWidth});

  const chartConfig = useMemo(() => {
    const config = {...tokenomicChartConfig};
    if (isMobile) {
      config.chart.height = 400;
      config.plotOptions.pie.dataLabels.enabled = false;
    }
    if (isLargeScreen) {
      config.chart.height = 1000;
    }
    return config;
  }, [isLargeScreen, isMobile]);

  return (
    <TokenomicBlockWrapper id={"tokenomic-block_tokenomic-block-wrapper"} isMobile={isMobile}>
      {!isMobile && (
        <Particles
          options={mainParticlesOptions}
          id="tokenomic-block-particles"
          className="particles"
        />
      )}
      <ContentWrapper>
        <BlockTitleWrapper>Tokenomics</BlockTitleWrapper>
        <ChartWrapper>
          <ChartComponentWrapper>
            <Chart chartConfig={chartConfig} id="tokenomic-chart" />
          </ChartComponentWrapper>
          <TextsWrapper>
            <TextColumnWrapper>
              <TextWrapper>
                <TextElementWrapper textAlign="center">Taxes: 5% / 5%</TextElementWrapper>
              </TextWrapper>
              <TextWrapper>
                <TextElementWrapper textAlign="left">
                  <BoldTextWrapper>1. Staking Commitment:</BoldTextWrapper> We have earmarked{" "}
                  <BoldTextWrapper>10% of our resources for staking</BoldTextWrapper>, demonstrating
                  our commitment to rewarding our holders. In conjunction with this, we are
                  strategizing to{" "}
                  <BoldTextWrapper>organically infuse liquidity into the market.</BoldTextWrapper>
                </TextElementWrapper>
                <TextElementWrapper textAlign="left">
                  <BoldTextWrapper>2. Mining Strategy:</BoldTextWrapper> The specific objectives
                  behind the <BoldTextWrapper>10% allocation for mining</BoldTextWrapper> are
                  currently confidential. However, we will be communicating our detailed strategies
                  and plans regarding bot operations and mining processes to all major holders in
                  due course.
                </TextElementWrapper>
              </TextWrapper>
            </TextColumnWrapper>
            <TextColumnWrapper>
              <TextWrapper>
                <TextElementWrapper textAlign="left">
                  <TextElementWrapper textAlign="left">
                    <BoldTextWrapper>1. Marketing Allocation:</BoldTextWrapper> We&apos;ve
                    dedicated&nbsp;
                    <BoldTextWrapper>10% of our budget to marketing</BoldTextWrapper> efforts, aimed
                    at engaging industry-leading advisors and marketers. A significant portion of
                    these funds will be conserved, with payments predominantly made in ETH to
                    promoters and influencers. This strategy is designed to mitigate any adverse
                    effects on our token&apos;s market performance.
                  </TextElementWrapper>
                  <TextElementWrapper textAlign="left">
                    <BoldTextWrapper>2. Team Expansion:</BoldTextWrapper> Our team fund is earmarked
                    for recruiting essential developers and moderators, a move that will elevate our
                    product to unprecedented heights.
                  </TextElementWrapper>
                </TextElementWrapper>
              </TextWrapper>
            </TextColumnWrapper>
          </TextsWrapper>
        </ChartWrapper>
      </ContentWrapper>
    </TokenomicBlockWrapper>
  );
});

const TokenomicBlockWrapper = styled.div<{isMobile: boolean}>`
  padding: 40px 0;
  position: relative;
  background: ${({isMobile}) => (isMobile ? theme.colors.darkGrey : "")};

  ${({isMobile}) =>
    isMobile &&
    css`
      background-image: url("/images/mobile-background.png");
      background-size: auto;
    `}
`;

const ContentWrapper = styled.div`
  margin: 0 30px;

  @media (max-width: ${mobileMaxWidthPx}) {
    margin: 0 15px;
  }
`;

const BlockTitleWrapper = styled.div`
  text-transform: uppercase;
  font-size: 4em;
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
  color: ${theme.colors.white};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 2em;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  flex-direction: column;
`;

const ChartComponentWrapper = styled.div`
  width: 70%;

  @media (max-width: ${mobileMaxWidthPx}) {
    width: 100%;
  }
`;

const TextsWrapper = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: ${mobileMaxWidthPx}) {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  font-size: 1.7em;
  height: 100%;
  border-radius: ${theme.borderRadius.lg};
  border: 4px solid ${theme.colors.tiffany};
  background: ${theme.colors.translucent.tiffany(0.1)};
  color: ${theme.colors.translucent.white(0.85)};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 1.2em;
    padding: 15px;
  }
`;

const TextElementWrapper = styled.div<{textAlign: string}>`
  font-family: ${theme.fonts.roboto.light};
  text-align: ${({textAlign}) => textAlign};

  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const BoldTextWrapper = styled.span`
  font-family: ${theme.fonts.roboto.black};
  font-weight: 800;
`;

const TextColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: ${mobileMaxWidthPx}) {
    width: 50%;
  }
`;

export default TokenomicBlock;
