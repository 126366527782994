import React from "react";
import Toast from "./components/Toast";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import "rc-tooltip/assets/bootstrap_white.css";

const App = () => {
  return (
    <>
      <Routes />
      <Toast />
    </>
  );
};

export default App;
