import React, {memo} from "react";
import Particles from "@tsparticles/react";
import styled from "styled-components";
import {blocksHeight, mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";
import {promoBlockParticlesOptions} from "./consts";
import Letters from "./Letters";

const PromoBlock = memo(() => {
  return (
    <PromoBlockWrapper className={"promo-block_promo-block-wrapper"}>
      <Particles
        options={promoBlockParticlesOptions}
        id="promo-block-particles"
        className="particles"
      />
      <Letters />
    </PromoBlockWrapper>
  );
});

const PromoBlockWrapper = styled.div`
  overflow: hidden;
  position: relative;
  opacity: 0;
  background: ${theme.colors.darkGrey};
  height: ${blocksHeight.promo.desktop};

  @media (max-width: ${mobileMaxWidthPx}) {
    height: ${blocksHeight.promo.mobile};
  }
`;

export default PromoBlock;
