import {memo} from "react";

const GitBookIcon = memo(() => {
  return (
    <svg
      width="512.000000pt"
      height="371.000000pt"
      viewBox="0 0 512.000000 371.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,371.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M2805 3686 c-22 -7 -613 -300 -1314 -650 -1192 -597 -1278 -641
-1333 -694 -70 -67 -121 -163 -144 -272 -21 -101 -14 -304 16 -415 44 -169
163 -361 272 -444 29 -22 388 -213 798 -424 l744 -385 12 -47 c30 -117 126
-240 231 -297 279 -152 617 16 676 336 l12 66 465 251 c256 138 591 319 745
402 154 83 302 163 328 177 l48 27 77 -38 c196 -98 431 -54 568 105 203 234
127 595 -154 733 -66 33 -85 38 -168 41 -178 8 -316 -60 -416 -206 -54 -78
-72 -145 -71 -257 1 -55 3 -110 5 -122 3 -20 -72 -63 -752 -426 l-755 -404
-66 62 c-156 146 -353 174 -540 79 -51 -25 -145 -111 -185 -168 l-20 -30 -485
254 c-903 472 -916 479 -958 521 -178 178 -226 546 -90 682 67 67 41 78 844
-343 1030 -540 976 -513 1038 -513 28 1 72 9 97 19 25 9 212 104 415 210 204
105 766 397 1250 648 595 309 889 467 909 488 24 26 27 37 23 70 -6 46 -38 84
-84 104 -180 77 -1535 718 -1678 794 -49 27 -114 55 -144 64 -65 19 -160 20
-216 2z m1897 -1846 c45 -13 84 -57 98 -111 10 -36 8 -47 -12 -87 -52 -106
-167 -124 -247 -38 -32 34 -41 119 -17 164 34 65 102 93 178 72z m-2320 -1246
c14 -8 36 -30 48 -49 18 -30 22 -47 18 -92 -4 -50 -9 -60 -46 -95 -37 -34 -47
-38 -93 -38 -105 0 -175 85 -149 182 27 97 128 139 222 92z"
        />
      </g>
    </svg>
  );
});

export default GitBookIcon;
