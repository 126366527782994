import React, {Fragment, memo} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import {routes} from "./routes";

const Routes = memo(() => {
  return (
    <Switch>
      {Object.keys(routes).map((routeKey) => {
        const route = routes[routeKey];
        const Guard = route.guard || Fragment;
        const Component = route.component;

        return (
          <Route
            key={`route-${route.path}`}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Component {...props} />
              </Guard>
            )}
          />
        );
      })}
      <Route path="*" render={() => <Redirect to={"/"} />} />
    </Switch>
  );
});

export default Routes;
