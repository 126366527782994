import {memo} from "react";

const TelegramIcon = memo(() => {
  return (
    <svg
      width="1200.000000pt"
      height="1004.000000pt"
      viewBox="0 0 1200.000000 1004.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,1004.000000) scale(0.100000,-0.100000)">
        <path
          d="M11299 10025 c-74 -12 -846 -308 -5545 -2126 -4917 -1902 -5466
-2117 -5532 -2162 -88 -61 -171 -148 -201 -213 -19 -39 -22 -61 -19 -120 3
-64 7 -78 41 -127 59 -85 165 -159 333 -234 39 -17 664 -223 1390 -458 1220
-394 1322 -426 1359 -418 26 6 1172 716 3248 2011 1764 1101 3222 2009 3240
2019 42 21 192 24 230 4 40 -21 36 -65 -12 -132 -97 -135 -1640 -1543 -4649
-4243 -261 -234 -439 -401 -448 -420 -17 -36 -228 -2519 -221 -2603 7 -95 76
-151 173 -140 25 2 58 15 76 30 18 13 381 335 806 715 819 732 801 718 872
694 16 -6 629 -446 1362 -978 733 -532 1351 -980 1373 -995 65 -44 211 -98
309 -115 301 -52 518 103 630 449 14 43 444 2069 956 4502 1021 4857 952 4504
910 4669 -55 216 -199 354 -409 392 -93 16 -160 16 -272 -1z"
        />
      </g>
    </svg>
  );
});

export default TelegramIcon;
