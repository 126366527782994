import {FC, memo} from "react";
import {IBioShapeProps} from "../types";
import {
  BioShapeItemDecoWrapper,
  BioShapeItemDescriptionWrapper,
  BioShapeItemExtraDescriptionWrapper,
  BioShapeItemImageWrapper,
  BioShapeItemMetaWrapper,
  BioShapeItemShapePathWrapper,
  BioShapeItemSvgWrapper,
  BioShapeItemTitleBlockWrapper,
  BioShapeItemTitleWrapper,
  BioShapeItemWrapper,
} from "../consts";

const BioShape4: FC<IBioShapeProps> = memo((props) => {
  const {title, description, extraDescription} = props;

  return (
    <BioShapeItemWrapper
      className="bio-shape-item bio-shape-item--style-6"
      data-animation-path-duration="2000"
      data-path-scaleX="0.7"
      data-path-scaleY="0.7"
      data-path-rotate="25"
      data-morph-path="M 279.8,41.26 C 332.2,40.04 397.1,40.63 432.5,79.42 470.9,121.7 455.7,191.8 458.3,249 460.6,300.4 481.9,363.6 448.9,403.1 402.7,458.2 311.1,450.1 239.3,453.9 183.9,456.9 113.3,471.5 74.23,432.1 18.97,376.3 29.82,251.5 45.32,198.4 59.64,149.2 95.01,111.8 134.9,84.73 176.6,56.36 229.4,42.43 279.8,41.26 Z"
      data-animation-image-duration="2000"
      data-animation-image-easing="easeOutExpo"
      data-image-translateY="-30"
      data-image-scaleX="0.9"
      data-image-scaleY="0.9"
    >
      <BioShapeItemSvgWrapper
        className="bio-shape-item__svg"
        width="500px"
        height="500px"
        viewBox="0 0 500 500"
      >
        <clipPath id="clipBioShape6">
          <BioShapeItemShapePathWrapper d="M 274.4,32.13 C 328.5,36.28 249,139.7 287.7,192.8 326.3,245.9 483.3,248.4 459,295 434.9,341.2 341.4,267.6 298,297.5 247.4,332.3 296,461.4 233.9,467.8 177.2,473.8 214.2,326.3 176,268.3 137.8,210.5 24.39,242.4 39.89,189.3 54.21,140.1 142,158.9 184.6,129.2 221.1,103.9 229.3,28.68 274.4,32.13 Z" />
        </clipPath>
        <BioShapeItemDecoWrapper className="bio-shape-item__deco">
          <use xlinkHref="#bioShapeDeco6" />
        </BioShapeItemDecoWrapper>
        <g clipPath="url(#clipBioShape6)">
          <BioShapeItemImageWrapper
            xlinkHref="/images/bioShapes/content.png"
            x="0"
            y="0"
            height="500px"
            width="500px"
          />
        </g>
      </BioShapeItemSvgWrapper>
      <BioShapeItemMetaWrapper className="bio-shape-item__meta">
        <BioShapeItemTitleBlockWrapper>
          <BioShapeItemTitleWrapper>{title}</BioShapeItemTitleWrapper>
        </BioShapeItemTitleBlockWrapper>
        <BioShapeItemDescriptionWrapper className="bio-shape-item__description">
          {description}
        </BioShapeItemDescriptionWrapper>
        <BioShapeItemExtraDescriptionWrapper className="bio-shape-item__extra-description">
          {extraDescription}
        </BioShapeItemExtraDescriptionWrapper>
      </BioShapeItemMetaWrapper>
    </BioShapeItemWrapper>
  );
});

export default BioShape4;
