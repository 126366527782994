import {FC, memo} from "react";
import {IBioShapeProps} from "../types";
import {
  BioShapeItemDecoWrapper,
  BioShapeItemDescriptionWrapper,
  BioShapeItemExtraDescriptionWrapper,
  BioShapeItemImageWrapper,
  BioShapeItemMetaWrapper,
  BioShapeItemShapePathWrapper,
  BioShapeItemSvgWrapper,
  BioShapeItemTitleBlockWrapper,
  BioShapeItemTitleWrapper,
  BioShapeItemWrapper,
} from "../consts";

const BioShape3: FC<IBioShapeProps> = memo((props) => {
  const {title, description, extraDescription} = props;

  return (
    <BioShapeItemWrapper
      className="bio-shape-item bio-shape-item--style-3"
      data-animation-path-duration="3500"
      data-path-elasticity="500"
      data-morph-path="M 193.7,217.3 C 236.4,228.3 279.7,242.7 320.9,231.8 362.6,220.9 446.8,197.1 457.6,241.5 469.3,289.8 378.7,308.3 330.2,319.2 278.5,330.8 222.3,319.2 172.1,302.2 125.2,286.4 33.08,273.2 45.14,225.2 57.22,177.1 145.7,204.8 193.7,217.3 Z"
      data-path-translateY="-20"
      data-animation-image-duration="1000"
      data-animation-image-easing="easeOutQuint"
      data-image-rotate="45"
      data-image-scaleX="0.8"
      data-image-scaleY="0.8"
      data-animation-deco-duration="2000"
      data-animation-deco-easing="easeOutElastic"
      data-deco-scaleX="0.7"
      data-deco-rotate="-10"
    >
      <BioShapeItemSvgWrapper
        className="bio-shape-item__svg"
        width="500px"
        height="500px"
        viewBox="0 0 500 500"
      >
        <clipPath id="clipBioShape3">
          <BioShapeItemShapePathWrapper d="M 184,127.4 C 235.4,92.39 319.7,79.27 359.9,132.2 383.2,163 357.1,216.6 355.8,258.8 354.8,291.2 371.3,332.9 352.9,356 306.1,414.4 205.1,419.3 153.7,367.2 123.8,336.8 128.6,272.1 136.1,225.2 142.1,187.8 157,145.7 184,127.4 Z" />
        </clipPath>
        <BioShapeItemDecoWrapper className="bio-shape-item__deco">
          <use xlinkHref="#bioShapeDeco3" />
        </BioShapeItemDecoWrapper>
        <g clipPath="url(#clipBioShape3)">
          <BioShapeItemImageWrapper
            xlinkHref="/images/bioShapes/content.png"
            x="0"
            y="0"
            height="500px"
            width="500px"
          />
        </g>
      </BioShapeItemSvgWrapper>
      <BioShapeItemMetaWrapper className="bio-shape-item__meta">
        <BioShapeItemTitleBlockWrapper>
          <BioShapeItemTitleWrapper>{title}</BioShapeItemTitleWrapper>
        </BioShapeItemTitleBlockWrapper>
        <BioShapeItemDescriptionWrapper className="bio-shape-item__description">
          {description}
        </BioShapeItemDescriptionWrapper>
        <BioShapeItemExtraDescriptionWrapper className="bio-shape-item__extra-description">
          {extraDescription}
        </BioShapeItemExtraDescriptionWrapper>
      </BioShapeItemMetaWrapper>
    </BioShapeItemWrapper>
  );
});

export default BioShape3;
