import {memo} from "react";
import styled from "styled-components";
import {theme} from "../../config/theme";
import {loaderDepthPx, loaderItemOffsetPx, loaderSizePx} from "./consts";

const Loader = memo(() => {
  return <LoaderWrapper />;
});

const LoaderWrapper = styled.div`
  aspect-ratio: 1;
  display: grid;
  transform: translate(calc(${loaderSizePx} * -0.5), calc(${loaderSizePx} * -0.5));
  width: calc(${loaderSizePx} + ${loaderDepthPx});

  :before,
  :after {
    content: "";
    grid-area: 1/1;
    animation: loaderAnimation 1.75s infinite;
    clip-path: polygon(
      ${loaderDepthPx} 0,
      100% 0,
      100% calc(100% - ${loaderDepthPx}),
      calc(100% - ${loaderDepthPx}) 100%,
      0 100%,
      0 ${loaderDepthPx}
    );
    background: conic-gradient(
      from -90deg at calc(100% - ${loaderDepthPx}) ${loaderDepthPx},
      ${theme.colors.white} 135deg,
      ${theme.colors.grey} 0 270deg,
      ${theme.colors.lightgrey} 0
    );
  }

  :after {
    animation-delay: -1s;
  }

  @keyframes loaderAnimation {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(${loaderItemOffsetPx}, 0);
    }
    50% {
      transform: translate(${loaderItemOffsetPx}, ${loaderItemOffsetPx});
    }
    75% {
      transform: translate(0, ${loaderItemOffsetPx});
    }
    100% {
      transform: translate(0, 0);
    }
  }
`;

export default Loader;
