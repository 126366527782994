export const drawLinkIcons = {
  hidden: () => {
    return {
      pathLength: 0,
      transition: {
        pathLength: {type: "spring", duration: 1.5, bounce: 0},
      },
    };
  },
  visible: () => {
    return {
      pathLength: 1,
      transition: {
        pathLength: {type: "spring", duration: 4, bounce: 0},
      },
    };
  },
};
