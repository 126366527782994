import {memo} from "react";

const TwitterIcon = memo(() => {
  return (
    <svg width="2400.000000pt" height="2453.000000pt" viewBox="0 0 2400.000000 2453.000000">
      <g transform="translate(0.000000,2453.000000) scale(0.100000,-0.100000)">
        <path
          d="M53 24453 c46 -66 9206 -13399 9288 -13519 l29 -42 -4632 -5383
  c-2547 -2961 -4656 -5412 -4686 -5446 l-54 -63 1059 0 1059 0 4095 4760 c2252
  2618 4096 4760 4099 4760 3 0 1477 -2142 3275 -4760 l3270 -4760 3573 0 3573
  0 -29 43 c-16 23 -2200 3201 -4852 7062 -2653 3861 -4824 7027 -4824 7036 -1
  8 2004 2346 4454 5194 2450 2848 4457 5182 4458 5187 2 4 -471 8 -1051 8
  l-1055 0 -3874 -4502 c-2130 -2476 -3877 -4503 -3883 -4504 -5 -2 -1402 2024
  -3104 4501 l-3094 4505 -3574 0 -3574 0 54 -77z m6690 -2390 c2838 -4059
  14345 -20521 14351 -20530 6 -10 -320 -13 -1617 -13 l-1625 0 -3410 4877
  c-1875 2683 -5242 7499 -7481 10702 -2239 3203 -4071 5827 -4071 5832 0 5 683
  9 1620 9 l1619 0 614 -877z"
        />
      </g>
    </svg>
  );
});

export default TwitterIcon;
