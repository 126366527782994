import React, {FC, memo, useMemo} from "react";
import styled from "styled-components";
import {defaultTransition} from "../../config/consts";
import {theme} from "../../config/theme";
import {defaultIconTheme} from "./consts";
import {IIconProps, IIconTheme} from "./types";

const Icon: FC<IIconProps> = memo((props) => {
  const {icon, disabled = false, iconTheme = {}, onClick = () => null} = props;
  const themeProps = {...defaultIconTheme, ...iconTheme};
  const Icon = icon;

  /**
   * Возвращает цвет svg
   */
  const iconSvgStroke = useMemo(() => {
    if (disabled && themeProps.strokeColor) {
      return theme.colors.grey;
    } else if (themeProps.strokeColor) {
      return themeProps.strokeColor;
    } else {
      return "";
    }
  }, [disabled, themeProps.strokeColor]);

  /**
   * Возвращает цвет svg при наведении
   */
  const iconSvgHoveredStroke = useMemo(() => {
    if (disabled) {
      return "";
    } else if (themeProps.strokeColor) {
      return themeProps.hoverColor;
    } else {
      return "";
    }
  }, [disabled, themeProps.hoverColor, themeProps.strokeColor]);

  return (
    <IconWrapper
      {...themeProps}
      disabled={disabled}
      iconSvgStroke={iconSvgStroke}
      iconSvgHoveredStroke={iconSvgHoveredStroke}
      onClick={(event) => onClick(event)}
    >
      <Icon />
    </IconWrapper>
  );
});

const IconWrapper = styled.div<Partial<IIconProps> & IIconTheme>`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  transform-origin: 50% 50%;
  vertical-align: middle;
  border-radius: ${theme.borderRadius.md};
  transition: all ${defaultTransition};
  width: ${({size, width}) => (size ? size : width)};
  height: ${({size, height}) => (size ? size : height)};
  cursor: ${({disabled}) => (disabled ? "initial" : "pointer")};
  padding: ${({padding}) => padding};
  background: ${({background}) => background};

  svg {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    transition: all ${defaultTransition};
    transform: rotate(${({rotate}) => rotate});
    fill: ${({color, disabled}) => (disabled ? theme.colors.grey : color)};
    stroke: ${({iconSvgStroke}) => iconSvgStroke};
    opacity: ${({opacity}) => opacity};
  }

  :hover {
    background: ${({hoverBackground, disabled}) => (disabled ? "" : hoverBackground)};

    svg {
      fill: ${({hoverColor, disabled}) => (disabled ? "" : hoverColor)};
      stroke: ${({iconSvgHoveredStroke}) => iconSvgHoveredStroke};
      opacity: ${({hoverOpacity, disabled}) => (disabled ? "" : hoverOpacity)};
    }
  }
`;

export default Icon;
