import {css} from "styled-components";
import {defaultTransition, desktopDefaultWidthPx, mobileMaxWidthPx} from "../config/consts";
import {theme} from "../config/theme";

export default css`
  .pieces-slider {
    position: relative;
    text-align: center;
    padding: 8rem 0;
  }

  .pieces-slider {
    padding: 0;
  }

  /* Make all slides absolutes and hide them */

  .pieces-slider__slide {
    position: absolute;
    right: 100%;
  }

  /* Define image dimensions and also hide them */

  .pieces-slider__image {
    max-width: 1200px;
    max-height: calc(1200px / 1.77);

    @media (min-width: ${desktopDefaultWidthPx}) {
      max-width: 1550px;
      max-height: calc(1550px / 1.77);
    }
  }

  .pieces-slider__image {
    visibility: hidden;
  }

  /* Hide the titles */

  .pieces-slider__text {
    text-indent: -9999px;
  }

  /* Canvas with viewport width and height */

  .pieces-slider__canvas {
    position: relative;
    width: 100vw;
    height: 100vh;
    transition: opacity ${defaultTransition};
  }

  /* Class for when we resize */

  .pieces-slider__canvas--hidden {
    opacity: 0;
    transition-duration: 0.3s;
  }

  /* Navigation buttons */

  .pieces-slider__button {
    position: absolute;
    left: 15px;
    top: 50%;
    width: 80px;
    height: 80px;
    font-size: 2.5em;
    margin: -25px 0 0 0;
    font-family: inherit;
    font-weight: bold;
    border: none;
    cursor: pointer;
    border-radius: ${theme.borderRadius.xs};
    transition:
      background ${defaultTransition},
      color ${defaultTransition};
    background: ${theme.colors.tiffany};
    color: ${theme.colors.white};

    :hover {
      background: ${theme.colors.translucent.white(0.9)};
      color: ${theme.colors.black};
    }

    @media (min-width: ${desktopDefaultWidthPx}) {
      width: 100px;
      height: 100px;
      font-size: 3em;
    }
  }

  .pieces-slider__button--next {
    left: auto;
    right: 15px;
  }

  /* Hide the buttons when no JS */

  .no-js .pieces-slider__button {
    display: none;
  }

  /* Media queries with styles for smaller screens */
  @media (max-width: ${mobileMaxWidthPx}) {
    .pieces-slider__image {
      max-width: 340px;
      max-height: calc(340px / 1.77);
    }

    .pieces-slider__canvas {
      width: 100vw;
      height: 100vw;
    }

    .pieces-slider__button {
      width: 40px;
      height: 40px;
      font-size: 1.3em;
      left: 5px;
      background: ${theme.colors.translucent.white(0.7)};
      color: ${theme.colors.black};
    }

    .pieces-slider__button--next {
      left: auto;
      right: 5px;
    }
  }
`;
