import {memo} from "react";

const DexscreenerIcon = memo(() => {
  return (
    <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M2245 5100 c-440 -65 -773 -199 -1080 -436 -85 -65 -87 -66 -134 -60
-100 13 -275 106 -375 200 -63 59 -73 46 -29 -38 220 -415 896 -957 1531
-1226 l52 -23 63 30 c112 54 187 73 287 73 100 0 175 -19 287 -73 l63 -30 52
23 c557 236 1202 724 1455 1100 41 62 103 174 103 188 0 20 -19 13 -53 -19
-106 -103 -268 -190 -379 -205 -46 -6 -49 -5 -131 59 -316 244 -667 383 -1115
442 -153 20 -442 18 -597 -5z"
        />
        <path
          d="M743 4138 c-69 -102 -148 -276 -189 -413 -53 -182 -56 -224 -64 -795
-8 -568 -14 -652 -66 -909 -45 -226 -117 -463 -189 -628 -39 -89 -141 -293
-175 -348 -43 -73 -53 -95 -39 -95 5 0 71 42 147 93 75 52 226 154 336 228
l199 134 21 -29 c11 -16 54 -75 95 -130 125 -170 145 -197 242 -330 52 -71
105 -142 119 -158 l25 -28 110 87 c61 48 176 140 257 204 l146 117 20 -21 c19
-21 157 -208 312 -422 20 -27 68 -93 106 -145 88 -118 226 -306 323 -439 41
-56 77 -101 82 -101 4 0 24 24 45 53 21 28 76 104 123 167 46 63 101 138 121
165 20 28 69 95 110 150 41 55 91 124 112 153 21 28 55 76 77 105 21 28 78
106 126 172 48 66 96 130 107 142 l20 22 102 -83 c85 -69 307 -244 391 -309
24 -18 24 -18 62 31 21 27 74 98 117 158 96 132 132 181 222 304 39 52 81 110
93 127 12 18 27 33 32 33 5 0 63 -37 128 -82 227 -158 534 -363 547 -366 18
-4 10 16 -36 93 -54 88 -167 324 -205 425 -9 25 -21 54 -26 65 -40 94 -118
387 -148 560 -39 223 -43 289 -51 830 -6 437 -11 557 -24 630 -19 102 -53 224
-86 305 -31 77 -126 259 -154 295 l-23 29 -59 -64 c-32 -36 -109 -115 -171
-175 -71 -69 -110 -114 -105 -120 20 -21 69 -123 88 -180 25 -79 26 -210 1
-284 -50 -149 -217 -300 -390 -353 -92 -28 -146 -39 -247 -50 l-112 -12 6 -41
c3 -22 8 -79 12 -126 l7 -87 211 -102 c116 -57 262 -128 324 -158 61 -30 112
-57 112 -61 0 -3 -15 -13 -32 -20 -18 -8 -89 -41 -158 -73 -69 -33 -174 -83
-235 -111 -180 -84 -230 -121 -338 -250 -143 -170 -282 -430 -422 -792 -38
-96 -83 -221 -101 -275 -8 -25 -19 -54 -24 -65 -5 -11 -25 -67 -44 -125 -73
-218 -95 -275 -106 -275 -11 0 -33 57 -106 275 -19 58 -39 114 -44 125 -5 11
-16 40 -24 65 -29 88 -120 330 -161 425 -52 122 -76 175 -130 280 -128 249
-276 443 -400 523 -37 24 -195 104 -290 147 -186 84 -306 146 -300 155 3 5 10
10 15 10 6 0 357 168 586 282 l41 20 7 87 c4 47 9 104 12 126 l6 41 -117 13
c-64 7 -128 16 -143 21 -15 4 -52 15 -82 24 -189 56 -354 202 -407 357 -25 74
-24 205 1 284 18 54 65 153 88 181 6 8 -17 36 -69 83 -43 40 -118 116 -167
169 -50 53 -93 98 -97 100 -4 2 -20 -16 -36 -40z"
        />
        <path
          d="M1352 3613 c-30 -77 -35 -112 -23 -155 26 -90 93 -153 213 -200 45
-18 75 -21 198 -22 126 0 154 3 213 23 37 12 67 28 67 35 0 7 -24 23 -52 36
-157 70 -345 168 -491 257 -49 29 -94 53 -101 53 -8 0 -18 -12 -24 -27z"
        />
        <path
          d="M3640 3586 c-165 -102 -506 -276 -541 -276 -6 0 -9 -6 -7 -12 3 -7
33 -24 69 -37 57 -21 81 -24 214 -24 165 -1 205 9 305 73 85 55 133 155 111
233 -21 74 -33 97 -50 96 -9 0 -54 -24 -101 -53z"
        />
        <path
          d="M2437 3326 c-149 -62 -253 -170 -316 -328 -30 -78 -51 -220 -51 -353
0 -90 -1 -94 -28 -117 -16 -12 -85 -50 -155 -83 -69 -33 -126 -63 -126 -67 -1
-4 32 -25 72 -47 224 -124 396 -315 527 -586 53 -108 180 -422 180 -442 0 -7
9 -13 20 -13 11 0 20 5 20 12 0 23 113 304 170 423 136 283 289 460 515 593
39 23 77 46 85 51 8 5 13 10 10 13 -3 2 -63 32 -135 67 -177 86 -175 83 -175
196 0 145 -30 315 -73 405 -57 121 -177 233 -303 280 -75 28 -163 26 -237 -4z"
        />
      </g>
    </svg>
  );
});

export default DexscreenerIcon;
