import {css} from "styled-components";
import {theme} from "../config/theme";

export default css`
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: ${theme.colors.darkGrey};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: ${theme.borderRadius.md};
    background: ${theme.colors.tiffany};
  }
`;
