import {memo} from "react";

const LogoIcon = memo(() => {
  return (
    <svg
      width="268.000000pt"
      height="271.000000pt"
      viewBox="0 0 268.000000 271.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,271.000000) scale(0.100000,-0.100000)">
        <path
          d="M1373 2677 c-43 -36 -292 -110 -339 -101 -31 6 -39 3 -53 -15 -13
-17 -50 -30 -151 -56 -86 -22 -138 -31 -144 -25 -13 13 -63 13 -71 1 -3 -5 -7
-23 -9 -40 -5 -42 -189 -251 -221 -251 -30 0 -50 -25 -47 -61 2 -22 -18 -56
-85 -149 -75 -103 -91 -120 -114 -120 -27 0 -59 -22 -59 -41 0 -5 7 -20 15
-32 13 -21 12 -59 -13 -363 -23 -286 -30 -343 -45 -358 -32 -32 -15 -76 29
-76 16 0 48 -35 128 -144 58 -79 106 -153 106 -165 0 -11 12 -28 27 -38 14
-10 65 -69 111 -132 71 -96 85 -121 86 -154 1 -52 25 -69 65 -46 27 16 35 15
147 -13 66 -17 126 -36 135 -44 8 -8 29 -14 47 -14 19 0 82 -28 169 -74 117
-63 141 -79 156 -110 21 -43 54 -48 89 -13 16 17 63 35 158 61 130 36 135 37
166 20 41 -22 81 -12 96 24 7 15 13 28 14 30 1 1 66 23 145 47 127 39 145 42
159 30 43 -39 102 -2 79 48 -10 22 4 43 191 278 144 181 209 255 225 257 13 2
31 12 41 23 17 19 17 21 1 52 -16 29 -17 51 -11 191 7 150 9 160 31 179 26 23
30 59 8 77 -12 10 -15 40 -15 154 0 139 1 143 25 164 14 12 25 28 25 35 0 22
-24 45 -50 49 -18 2 -49 34 -117 123 -50 66 -94 133 -98 148 -4 16 -17 33 -31
39 -33 15 -158 251 -150 282 3 13 -1 32 -10 45 -13 18 -22 22 -49 17 -48 -9
-304 108 -324 148 -12 27 -16 28 -62 22 -67 -9 -303 55 -336 90 -27 30 -34 30
-70 1z m3 -51 c9 -23 35 -28 60 -11 26 17 26 17 166 -21 54 -15 95 -31 92 -35
-3 -5 -137 -9 -297 -9 -318 1 -319 1 -152 49 44 13 84 27 90 32 14 14 35 11
41 -5z m384 -111 c0 -16 -54 -149 -106 -262 -46 -101 -50 -101 -249 38 -88 61
-180 123 -204 137 -79 49 -122 83 -116 92 4 6 136 10 341 10 289 0 334 -2 334
-15z m-805 -20 c-11 -8 -24 -18 -30 -23 -10 -8 -55 -35 -250 -150 -123 -72
-217 -123 -221 -119 -2 1 36 46 83 100 52 59 93 97 105 97 10 0 29 11 42 25
15 16 46 30 87 40 35 9 73 20 84 25 19 9 57 16 100 18 l20 1 -20 -14z m1046
-47 c101 -44 124 -58 134 -82 7 -16 25 -34 41 -41 23 -9 42 -37 87 -124 32
-61 55 -111 51 -111 -14 0 -464 404 -464 417 0 7 3 5 151 -59z m-911 35 c14
-9 89 -61 166 -115 78 -54 147 -98 153 -98 6 0 11 -4 11 -10 0 -5 4 -10 10
-10 16 0 140 -91 140 -103 0 -21 -39 -34 -325 -107 -110 -28 -209 -55 -220
-60 -35 -17 -248 -69 -257 -63 -14 8 -14 9 182 443 65 144 84 161 140 123z
m903 -140 c96 -87 203 -184 238 -215 35 -31 60 -60 57 -64 -4 -3 -103 5 -220
20 -282 35 -280 35 -303 40 -11 2 -39 7 -62 11 -73 12 -72 33 8 203 16 34 29
64 29 66 0 2 9 24 20 50 15 32 27 46 40 46 11 0 95 -68 193 -157z m-1038 70
c-21 -54 -214 -476 -218 -479 -1 -1 -14 -4 -29 -8 -23 -5 -44 8 -152 95 -76
61 -126 108 -126 119 0 16 107 90 131 90 5 0 9 4 9 9 0 5 10 13 22 17 13 4 70
36 128 72 111 69 240 142 252 142 4 0 -4 -26 -17 -57z m871 -314 c82 -11 158
-22 169 -24 11 -2 82 -11 159 -21 l138 -18 16 -32 c15 -32 14 -39 -27 -176
-82 -279 -133 -428 -146 -428 -7 0 -75 93 -151 208 -76 114 -180 268 -231 342
-85 123 -108 170 -84 170 5 0 76 -9 157 -21z m-1281 -94 c67 -55 128 -110 133
-121 12 -24 -10 -50 -100 -117 -26 -19 -69 -54 -95 -76 -133 -112 -268 -221
-274 -221 -8 0 6 74 25 126 8 23 42 142 77 264 58 207 78 255 101 248 5 -2 65
-48 133 -103z m1041 92 c3 -8 -14 -65 -36 -128 -23 -63 -66 -184 -95 -269 -87
-249 -87 -250 -110 -250 -12 0 -25 -4 -30 -9 -6 -6 -64 34 -151 102 -77 62
-155 123 -173 137 -18 13 -45 35 -59 47 -15 13 -32 27 -37 31 -162 117 -163
123 -15 161 47 12 94 26 105 31 21 9 70 23 310 85 77 20 165 45 195 55 66 23
89 25 96 7z m79 -44 c14 -21 53 -78 86 -128 33 -49 90 -135 127 -190 37 -55
70 -101 75 -103 4 -2 7 -10 7 -18 0 -8 3 -14 8 -14 4 0 12 -9 17 -20 6 -11 37
-59 69 -106 88 -128 103 -123 -306 -90 -352 29 -358 30 -358 79 0 10 11 47 26
82 14 36 51 140 84 232 124 358 117 345 165 276z m-1338 -64 c-26 -97 -118
-413 -130 -446 -6 -18 -14 -33 -18 -33 -3 0 -11 55 -16 122 -9 108 -8 129 8
172 16 46 157 251 166 242 2 -2 -2 -28 -10 -57z m2147 -301 c53 -161 96 -303
96 -315 0 -12 -5 -25 -12 -29 -13 -8 -186 -7 -290 3 -57 5 -70 9 -83 30 -15
23 -14 32 29 171 85 280 128 417 133 424 18 31 38 -13 127 -284z m35 152 c39
-50 68 -99 74 -124 13 -55 20 -272 10 -291 -7 -11 -34 60 -90 237 -45 139 -85
264 -88 278 l-6 25 18 -21 c10 -12 47 -59 82 -104z m-1784 -72 c-7 -84 -9
-109 -30 -477 -9 -151 -18 -278 -21 -283 -3 -4 -12 -8 -20 -8 -14 0 -220 169
-365 300 -64 57 -66 60 -65 106 l1 46 165 137 c290 240 304 251 323 251 17 0
18 -6 12 -72z m137 -11 c394 -308 428 -338 428 -368 0 -27 -18 -41 -267 -206
-148 -97 -278 -178 -289 -181 -12 -2 -25 1 -29 7 -6 11 32 781 41 809 6 21 21
14 116 -61z m-708 -213 c6 -62 4 -80 -8 -93 -18 -20 -20 -44 -6 -71 7 -13 4
-37 -11 -85 -11 -37 -23 -65 -25 -62 -8 7 29 387 37 387 3 0 9 -34 13 -76z
m1620 -186 c313 -24 315 -24 302 -71 -5 -18 -184 -248 -244 -312 -10 -11 -34
-40 -53 -65 -123 -161 -183 -225 -208 -225 -25 0 -27 5 -82 215 -30 118 -58
222 -62 230 -10 23 -47 176 -47 195 0 8 8 27 17 41 13 21 22 25 47 20 17 -2
165 -15 330 -28z m-1532 -45 c221 -182 420 -367 406 -378 -2 -1 -67 -52 -145
-114 -77 -61 -146 -111 -152 -111 -12 0 -8 -15 -91 335 -34 143 -64 268 -66
278 -7 25 9 21 48 -10z m1094 21 c17 -4 26 -20 37 -62 9 -32 27 -97 40 -147
39 -140 117 -444 117 -455 0 -6 -9 -10 -19 -10 -20 0 -221 69 -277 95 -17 8
-96 38 -175 66 -79 28 -162 59 -184 69 -22 10 -64 25 -92 35 -31 9 -53 23 -53
31 0 8 36 38 81 66 45 29 169 112 277 185 109 73 204 132 212 132 8 1 25 -2
36 -5z m-1091 -311 c36 -152 65 -279 65 -281 0 -13 -31 25 -110 133 -49 69
-90 134 -90 145 0 12 -5 30 -10 40 -8 15 -4 43 15 112 43 155 46 163 57 145 5
-9 38 -141 73 -294z m2307 285 c21 -10 29 12 -120 -337 -106 -249 -131 -295
-149 -278 -3 3 -31 135 -63 294 l-58 287 21 28 20 27 166 -6 c91 -3 174 -10
183 -15z m-379 -177 c14 -75 40 -203 57 -284 30 -145 30 -149 13 -173 l-19
-25 -304 1 c-167 0 -306 3 -309 5 -6 7 16 47 42 76 38 42 81 94 107 129 25 33
156 194 283 347 90 109 95 106 130 -76z m402 -38 c-6 -125 -10 -151 -26 -169
-11 -11 -19 -29 -19 -39 0 -16 -189 -219 -197 -211 -9 9 232 566 244 566 3 0
2 -66 -2 -147z m-1852 -295 c25 -68 53 -150 62 -183 10 -33 35 -106 56 -162
41 -108 46 -133 28 -133 -6 0 -75 44 -152 97 -78 53 -171 116 -207 141 -36 24
-72 49 -81 55 -8 7 -24 17 -35 23 -43 25 -32 46 67 126 19 15 70 56 114 92 44
36 85 65 91 65 7 1 32 -54 57 -121z m77 102 c30 -12 69 -27 85 -35 17 -7 104
-40 195 -73 272 -98 414 -152 422 -160 12 -12 -15 -33 -107 -85 -44 -24 -133
-76 -198 -116 -65 -39 -122 -71 -128 -71 -5 0 -9 -4 -9 -8 0 -5 -22 -19 -50
-32 l-49 -23 -33 25 c-28 22 -39 44 -77 159 -24 74 -63 186 -87 250 -23 65
-46 133 -49 153 -9 44 3 46 85 16z m1548 -272 c-19 -24 -76 -95 -126 -159
-125 -157 -142 -169 -303 -218 -73 -22 -134 -39 -136 -37 -2 2 16 19 39 38 72
57 426 317 461 338 17 11 45 36 60 55 16 20 31 34 34 31 3 -3 -10 -24 -29 -48z
m-153 -6 c28 -4 50 -12 50 -18 0 -6 -112 -95 -250 -198 -226 -168 -253 -186
-280 -180 -16 4 -32 8 -33 9 -2 2 -26 82 -53 177 -41 145 -47 177 -37 196 l11
22 271 -1 c149 0 294 -4 321 -7z m-643 -39 c9 -10 37 -94 62 -188 41 -148 46
-172 34 -193 -15 -28 -10 -29 -338 18 -113 16 -233 32 -267 36 -35 4 -63 10
-63 15 0 4 42 33 93 64 269 163 443 264 453 265 6 0 18 -8 26 -17z m-932 -121
c96 -64 186 -127 200 -140 l25 -23 -40 6 c-97 16 -190 45 -205 63 -8 11 -24
23 -36 26 -19 6 -50 43 -122 150 -23 35 -28 46 -15 42 10 -4 96 -60 193 -124z
m720 -262 c128 -17 236 -33 238 -35 9 -9 -4 -14 -123 -49 -109 -31 -121 -33
-142 -19 -13 8 -30 13 -38 9 -16 -6 -159 62 -220 103 l-30 21 40 0 c22 0 146
-14 275 -30z"
        />
      </g>
    </svg>
  );
});

export default LogoIcon;
