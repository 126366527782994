import {FC, memo} from "react";
import {IBioShapeProps} from "../types";
import {
  BioShapeItemDecoWrapper,
  BioShapeItemDescriptionWrapper,
  BioShapeItemExtraDescriptionWrapper,
  BioShapeItemImageWrapper,
  BioShapeItemMetaWrapper,
  BioShapeItemShapePathWrapper,
  BioShapeItemSvgWrapper,
  BioShapeItemTitleBlockWrapper,
  BioShapeItemTitleWrapper,
  BioShapeItemWrapper,
} from "../consts";

const BioShape5: FC<IBioShapeProps> = memo((props) => {
  const {title, description, extraDescription} = props;

  return (
    <BioShapeItemWrapper
      className="bio-shape-item bio-shape-item--style-7"
      data-animation-path-duration="2500"
      data-animation-path-easing="easeOutExpo"
      data-path-rotate="10"
      data-path-scaleX="0.5"
      data-morph-path="M 251.1,32.08 C 320.8,39.34 403.4,70.51 435.8,132.7 476.2,210.5 460.8,325.2 406.4,394 360.4,452.2 271,467.5 196.8,469.3 144.1,470.5 65.63,471.7 45.51,423 17.77,355.8 140.2,302.9 148.3,230.6 154.4,177.4 80.17,122.4 106.2,75.55 130.7,31.47 200.9,26.86 251.1,32.08 Z"
      data-animation-image-duration="1500"
      data-animation-image-easing="easeOutCubic"
      data-image-rotate="-30"
      data-animation-deco-duration="1500"
      data-animation-deco-easing="easeOutExpo"
      data-deco-scaleX="0.7"
      data-deco-translateX="-100"
      data-deco-translateY="-100"
      data-deco-rotate="50"
    >
      <BioShapeItemSvgWrapper
        className="bio-shape-item__svg"
        width="500px"
        height="500px"
        viewBox="0 0 500 500"
      >
        <clipPath id="clipBioShape7">
          <BioShapeItemShapePathWrapper d="M 280.1,34.42 C 465.8,29.89 514.6,354 417.3,392.3 318.9,423.2 332.3,114.7 233.3,143.6 134,172.6 294.3,390.5 212,453.2 174.8,481.6 106.3,459.6 74.54,425.3 21.22,367.7 30.13,244.7 45.63,191.6 59.95,142.4 95.32,105 135.2,77.89 176.9,49.52 229.9,29.96 280.1,34.42 Z" />
        </clipPath>
        <BioShapeItemDecoWrapper className="bio-shape-item__deco">
          <use xlinkHref="#bioShapeDeco7" />
        </BioShapeItemDecoWrapper>
        <g clipPath="url(#clipBioShape7)">
          <BioShapeItemImageWrapper
            xlinkHref="/images/bioShapes/content.png"
            x="0"
            y="0"
            height="500px"
            width="500px"
          />
        </g>
      </BioShapeItemSvgWrapper>
      <BioShapeItemMetaWrapper className="bio-shape-item__meta">
        <BioShapeItemTitleBlockWrapper>
          <BioShapeItemTitleWrapper>{title}</BioShapeItemTitleWrapper>
        </BioShapeItemTitleBlockWrapper>
        <BioShapeItemDescriptionWrapper className="bio-shape-item__description">
          {description}
        </BioShapeItemDescriptionWrapper>
        <BioShapeItemExtraDescriptionWrapper className="bio-shape-item__extra-description">
          {extraDescription}
        </BioShapeItemExtraDescriptionWrapper>
      </BioShapeItemMetaWrapper>
    </BioShapeItemWrapper>
  );
});

export default BioShape5;
