import {initParticlesEngine} from "@tsparticles/react";
import {loadSlim} from "@tsparticles/slim";
import React, {FC, memo, useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import BlocksDivider from "../../components/BlocksDivider";
import Loader from "../../components/Loader";
import AboutBlock from "./AboutBlock";
import {blocksAppearDuration, loaderHideDuration} from "./consts";
import FooterBlock from "./FooterBlock";
import HeaderBlock from "./HeaderBlock";
import PromoBlock from "./PromoBlock";
import anime from "animejs";
import RoadmapBlock from "./RoadmapBlock";
import TokenomicBlock from "./TokenomicBlock";

const MainPage: FC = memo(() => {
  const [isParticlesReady, setIsParticlesReady] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setIsParticlesReady(true);
    });
  }, []);

  const handleHideLoader = useCallback(() => {
    anime.timeline({loop: false}).add({
      targets: ".main-page_loader-wrapper",
      easing: "easeInOutCirc",
      opacity: [1, 0],
      duration: loaderHideDuration,
    });
  }, []);

  const handleAppearBlocks = useCallback(() => {
    anime.timeline({loop: false}).add({
      targets: [
        ".main-page_blocks-wrapper",
        ".blocks-divider_blocks-divider-wrapper",
        ".header-block_header-block-wrapper",
        ".promo-block_promo-block-wrapper",
        ".about-block_about-block-wrapper",
      ],
      easing: "easeInOutCirc",
      opacity: [0, 1],
      duration: blocksAppearDuration,
      delay: loaderHideDuration,
    });
  }, []);

  useEffect(() => {
    if (isParticlesReady) {
      handleHideLoader();
      handleAppearBlocks();
    }
  }, [handleAppearBlocks, handleHideLoader, isParticlesReady]);

  return (
    <MainPageWrapper>
      {isParticlesReady && (
        <BlocksWrapper className={"main-page_blocks-wrapper"}>
          <HeaderBlock />
          <BlocksDivider />
          <PromoBlock />
          <BlocksDivider />
          <AboutBlock />
          <BlocksDivider />
          <RoadmapBlock />
          <BlocksDivider />
          <TokenomicBlock />
          <BlocksDivider />
          <FooterBlock />
        </BlocksWrapper>
      )}
      <LoaderWrapper className={"main-page_loader-wrapper"}>
        <Loader />
      </LoaderWrapper>
    </MainPageWrapper>
  );
});

const MainPageWrapper = styled.div``;

const BlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default MainPage;
