import React, {memo, useState} from "react";
import styled from "styled-components";
import Icon from "../../../../components/Icon";
import CheckIcon from "../../../../components/Icons/CheckIcon";
import {defaultTransition, mobileMaxWidthPx} from "../../../../config/consts";
import {theme} from "../../../../config/theme";

const columnsData = [
  {
    title: "For Developers",
    description: "Pretorian Team offers full - package services for fair price.",
    checks: [
      "Helping with funding",
      "Smart contract development",
      "Website development",
      "Telegram bot development",
      "Tokenomics management",
      "Marketing assistance",
      "Advisory",
      "KYC & audit",
      "Telegram chat bot that unites ecosystem together",
    ],
  },
  {
    title: "For Investors",
    description: "Possibility to have long term income mixed with short term opportunities.",
    checks: [
      "Returns on upcoming launches",
      "Unique mining mechanism (short term)",
      "Progressive Revenue share system",
      "Staking (long term)",
    ],
  },
];

const RoadmapBlockContent = memo(() => {
  const [hoveredCheck, setHoveredCheck] = useState("");

  return (
    <ColumnsWrapper>
      {columnsData.map((column) => (
        <ColumnWrapper key={column.title}>
          <ColumnFirstRowWrapper>
            <ColumnTitleWrapper>{column.title}</ColumnTitleWrapper>
            <ColumnDescriptionWrapper>{column.description}</ColumnDescriptionWrapper>
          </ColumnFirstRowWrapper>
          <ColumnSecondRowWrapper>
            <ColumnChecksTitleWrapper>What&apos;s included:</ColumnChecksTitleWrapper>
            <ColumnChecksWrapper>
              {column.checks.map((check) => (
                <ColumnCheckWrapper
                  key={check}
                  onMouseEnter={() => setHoveredCheck(check)}
                  onMouseLeave={() => setHoveredCheck("")}
                >
                  <Icon
                    icon={CheckIcon}
                    iconTheme={{
                      hoverBackground: theme.colors.translucent.white(0.3),
                      padding: "6px",
                      color:
                        hoveredCheck === check
                          ? theme.colors.white
                          : theme.colors.translucent.white(0.8),
                      background:
                        hoveredCheck === check ? theme.colors.translucent.white(0.25) : "",
                    }}
                  />
                  <ColumnCheckTextWrapper check={check} hoveredCheck={hoveredCheck}>
                    {check}
                  </ColumnCheckTextWrapper>
                </ColumnCheckWrapper>
              ))}
            </ColumnChecksWrapper>
          </ColumnSecondRowWrapper>
        </ColumnWrapper>
      ))}
    </ColumnsWrapper>
  );
});

const ColumnsWrapper = styled.div`
  display: flex;
  gap: 40px;
  color: ${theme.colors.translucent.white(0.85)};

  @media (max-width: ${mobileMaxWidthPx}) {
    flex-direction: column;
  }
`;

const ColumnWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: ${mobileMaxWidthPx}) {
    width: auto;
  }
`;

const ColumnRowWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 20px;
  font-size: 1.7em;
  border: 4px solid ${theme.colors.tiffany};
  background: ${theme.colors.translucent.tiffany(0.1)};
  border-radius: ${theme.borderRadius.lg};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 1.2em;
    padding: 15px;
  }
`;

const ColumnFirstRowWrapper = styled(ColumnRowWrapper)`
  @media (min-width: ${mobileMaxWidthPx}) {
    min-height: clamp(130px, calc(260px - (100vw - 640px) / 400 * 80), 260px);
  }
`;

const ColumnSecondRowWrapper = styled(ColumnRowWrapper)`
  @media (min-width: ${mobileMaxWidthPx}) {
    min-height: 330px;
  }
`;

const ColumnTitleWrapper = styled.div`
  font-size: 1.2em;
  text-align: center;
`;

const ColumnDescriptionWrapper = styled.div`
  text-align: justify;
  font-family: ${theme.fonts.roboto.light};
`;

const ColumnChecksTitleWrapper = styled.div`
  margin-bottom: -10px;
`;

const ColumnChecksWrapper = styled.div``;

const ColumnCheckWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const ColumnCheckTextWrapper = styled.div<{check: string; hoveredCheck: string}>`
  cursor: pointer;
  transition: color ${defaultTransition};
  font-family: ${theme.fonts.roboto.light};
  color: ${({check, hoveredCheck}) =>
    hoveredCheck === check ? theme.colors.white : theme.colors.translucent.white(0.8)};
`;

export default RoadmapBlockContent;
