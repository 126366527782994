export const theme = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    lightgrey: "#AAAAAA",
    semiLightGrey: "rgb(150, 150, 150)",
    grey: "#707070",
    darkGrey: "#1b1b1b",
    transparent: "rgba(0, 0, 0, 0)",
    tiffany: "#0abab5",
    translucent: {
      white: (opacity: number) => `rgba(255, 255, 255, ${opacity})`,
      black: (opacity: number) => `rgba(0, 0, 0, ${opacity})`,
      tiffany: (opacity: number) => `rgba(10, 186, 181, ${opacity})`,
    },
  },
  borderRadius: {
    xs: "2.5px",
    sm: "5px",
    md: "10px",
    lg: "15px",
  },
  fonts: {
    roboto: {
      black: "'Roboto-Black', Arial, sans-serif",
      bold: "'Roboto-Bold', Arial, sans-serif",
      light: "'Roboto-Light', Arial, sans-serif",
      medium: "'Roboto-Medium', Arial, sans-serif",
      regular: "'Roboto-Regular', Arial, sans-serif",
      thin: "'Roboto-Thin', Arial, sans-serif",
    },
    sizes: {
      xxs: "8px",
      xs: "10px",
      sm: "12px",
      md: "14px",
      lg: "16px",
      xlg: "18px",
      xxlg: "20px",
    },
  },
};
