import {css} from "styled-components";
import {blocksHeight, mobileMaxWidthPx} from "../config/consts";

export default css`
  .particles {
    position: absolute;
    width: 100%;
  }

  #promo-block-particles {
    height: ${blocksHeight.promo.desktop};

    @media (max-width: ${mobileMaxWidthPx}) {
      height: ${blocksHeight.promo.mobile};
    }
  }

  #tokenomic-block-particles,
  #roadmap-block-particles,
  #about-block-particles {
    height: 100%;
    top: 0;
    z-index: -1;
  }
`;
