import Particles from "@tsparticles/react";
import React, {memo, useEffect, useRef} from "react";
import {useMediaQuery} from "react-responsive";
import styled, {css} from "styled-components";
import {theme} from "../../../config/theme";
import {mainParticlesOptions} from "../TokenomicBlock/consts";
import BioShape2 from "./BioShapes/BioShape2";
import BioShape3 from "./BioShapes/BioShape3";
import BioShape4 from "./BioShapes/BioShape4";
import BioShape5 from "./BioShapes/BioShape5";
import BioShapesSvgDefs from "./BioShapes/BioShapesSvgDefs";
import {mobileMaxWidth, mobileMaxWidthPx} from "../../../config/consts";
import {ImgItem} from "./BioShapes/bioShapesClass";
import BioShape1Text from "./BioShapesTexts/BioShape1Text";
import BioShape2Text from "./BioShapesTexts/BioShape2Text";
import BioShape3Text from "./BioShapesTexts/BioShape3Text";
import BioShape4Text from "./BioShapesTexts/BioShape4Text";

const AboutBlock = memo(() => {
  const aboutBlockWrapperRef = useRef(null);

  const isMobile = useMediaQuery({maxWidth: mobileMaxWidth});

  useEffect(() => {
    const items = Array.from(document.querySelectorAll(".bio-shape-item"));
    items.forEach((item) => new ImgItem(item));
  }, []);

  return (
    <AboutBlockWrapper
      ref={aboutBlockWrapperRef}
      className={"about-block_about-block-wrapper"}
      id={"about-block_about-block-wrapper"}
      isMobile={isMobile}
    >
      {!isMobile && (
        <Particles
          options={mainParticlesOptions}
          id="about-block-particles"
          className="particles"
        />
      )}
      <BlockTitleWrapper>Key Features</BlockTitleWrapper>
      {/*{!isMobile && <Parallax aboutBlockWrapperRef={aboutBlockWrapperRef} />}*/}
      <BioShapesWrapper>
        {isMobile && <MobileBackground />}
        <BioShapeWrapper>
          <BioShape2
            title="AI launch bot"
            description="New prelaunch method"
            extraDescription="Fair distribution among real supporters that benefits both sides"
          />
          <BioShape1Text />
        </BioShapeWrapper>
        <BioShapeWrapper>
          {!isMobile && <BioShape2Text />}
          <BioShape3
            title="Smart contract development"
            description="AI security advisor"
            extraDescription="Zero possibility for honeypot, scam functions and any suspicious activity with tokens"
          />
          {isMobile && <BioShape2Text />}
        </BioShapeWrapper>
        <BioShapeWrapper>
          <BioShape4
            title="AI marketing supervisor"
            description="Raffles, contests, collaborations"
            extraDescription="First mover solution for both – developers and investors"
          />
          <BioShape3Text />
        </BioShapeWrapper>
        <BioShapeWrapper>
          {!isMobile && <BioShape4Text />}
          <BioShape5
            title="$PRET AI token"
            description="Revenue share from development"
            extraDescription="Hold $PRET to get access to our revenue share system, future token distribution and unique access to upcoming launches."
          />
          {isMobile && <BioShape4Text />}
        </BioShapeWrapper>
      </BioShapesWrapper>
      <BioShapesSvgDefs />
    </AboutBlockWrapper>
  );
});

const AboutBlockWrapper = styled.div<{isMobile: boolean}>`
  padding-top: 40px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  ${({isMobile}) =>
    isMobile &&
    css`
      background-image: url("/images/mobile-background.png");
      background-size: auto;
    `}

  @media (min-width: ${mobileMaxWidthPx}) {
    padding-bottom: 40px;
  }
`;

const BlockTitleWrapper = styled.div`
  text-transform: uppercase;
  font-size: 4em;
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
  color: ${theme.colors.translucent.white(0.9)};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 2em;
    margin-bottom: 0;
  }
`;

const BioShapesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  gap: 50px;

  @media (max-width: ${mobileMaxWidthPx}) {
    margin: 0 15px 30px 15px;
    gap: 40px;
  }
`;

const BioShapeWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${mobileMaxWidthPx}) {
    flex-direction: column;
  }
`;

export const BioShapeTextWrapper = styled.div`
  font-size: 1.7em;
  flex-shrink: 5;
  padding: 25px;
  text-align: left;
  font-family: ${theme.fonts.roboto.light};
  border-radius: ${theme.borderRadius.lg};
  border: 4px solid ${theme.colors.tiffany};
  background: ${theme.colors.translucent.tiffany(0.1)};
  color: ${theme.colors.translucent.white(0.85)};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 1.2em;
    padding: 15px;
    margin-top: -40px;
  }

  div {
    font-family: ${theme.fonts.roboto.light};
  }
`;

export const ListElementWrapper = styled.div`
  margin-left: 15px;
`;

export const BoldTextWrapper = styled.span`
  font-family: ${theme.fonts.roboto.black};
  font-weight: 800;
`;

const MobileBackground = styled.div`
  width: 100%;
  position: absolute;
  z-index: -1;
  height: 6000px;
  top: 0;
  left: 0;
  background: ${theme.colors.darkGrey};
`;

export default AboutBlock;
