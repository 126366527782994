import React, {FC, memo} from "react";
import {ToastContainer, Bounce} from "react-toastify";
import {IToastProps} from "./types";

const Toast: FC<Partial<IToastProps>> = memo((props) => {
  const {
    position = "top-right",
    autoClose = 4000,
    pauseOnHover = true,
    pauseOnFocusLoss = false,
    closeOnClick = true,
    draggable = false,
    theme = "dark",
    transition = Bounce,
    toastClassName = "toast-common",
  } = props;
  return (
    <ToastContainer
      position={position}
      autoClose={autoClose}
      pauseOnHover={pauseOnHover}
      pauseOnFocusLoss={pauseOnFocusLoss}
      closeOnClick={closeOnClick}
      draggable={draggable}
      theme={theme}
      transition={transition}
      toastClassName={toastClassName}
      stacked={true}
    />
  );
});

export default Toast;
