import {memo} from "react";
import {BioShapeTextWrapper, BoldTextWrapper, ListElementWrapper} from "../index";

const BioShape3Text = memo(() => {
  return (
    <BioShapeTextWrapper>
      <div>
        Our bot facilitates developer collaboration with upcoming launches by offering complimentary
        whitelist spots for promotional distribution to their followers. Additionally, our bot
        provides fundamental audience engagement features, such as:
      </div>
      <ListElementWrapper>
        1. <BoldTextWrapper>Question based contests</BoldTextWrapper> – Developers can pose
        questions and the bot selects participants who respond correctly.
      </ListElementWrapper>
      <ListElementWrapper>
        2. <BoldTextWrapper>Random Selection from Recent Messages</BoldTextWrapper> – Developers
        have the flexibility to specify the exact number of recent messages and bot will randomly
        choose winner among them (e.g., 10, 15, 20, 30, etc.).
      </ListElementWrapper>
      <ListElementWrapper>
        3. <BoldTextWrapper>Active Chat Raffles</BoldTextWrapper> – Random selection of winners from
        chat participants based on different events
      </ListElementWrapper>
      <ListElementWrapper>
        4. <BoldTextWrapper>Investor Twitter Promotion Voting</BoldTextWrapper> – A feature that
        allows voting for the best promotional tweets by investors.
      </ListElementWrapper>
      <div>
        This overview offers a glimpse into the current capabilities of our bot and hints at the
        exciting features we plan to introduce in the future.
      </div>
    </BioShapeTextWrapper>
  );
});

export default BioShape3Text;
