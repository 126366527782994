import {createGlobalStyle} from "styled-components";
import commonClasses from "./common";
import scrollbarClasses from "./scrollbar";
import toastClasses from "./toast";
import tooltipClasses from "./tooltip";
import particlesClasses from "./particles";
import piecesSliderClasses from "./piecesSlider";
import chartClasses from "./chart";
import accordionClasses from "./accordion";

const GlobalStyle = createGlobalStyle`
  ${commonClasses}
  ${toastClasses}
  ${scrollbarClasses}
  ${tooltipClasses}
  ${particlesClasses}
  ${piecesSliderClasses}
  ${chartClasses}
  ${accordionClasses}
`;

export default GlobalStyle;
