import {memo} from "react";

const UniswapIcon = memo(() => {
  return (
    <svg width="300.000000pt" height="300.000000pt" viewBox="0 0 300.000000 300.000000">
      <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M210 2895 c0 -9 162 -194 301 -345 64 -69 198 -201 298 -294 154
-143 182 -172 179 -194 -4 -31 -39 -58 -99 -73 -90 -24 -80 -33 -259 254 -47
75 -88 136 -92 137 -4 0 27 -59 67 -131 41 -72 79 -147 85 -167 13 -48 -3
-106 -43 -156 -39 -50 -73 -142 -82 -226 -15 -137 -70 -250 -205 -419 -93
-117 -116 -155 -135 -217 -32 -106 -7 -245 67 -369 23 -38 55 -96 72 -128 l31
-58 46 5 c61 8 194 55 254 90 66 39 126 95 142 133 7 18 13 56 13 85 0 72 -28
111 -134 187 -95 69 -140 128 -150 195 -8 53 4 103 48 199 18 40 40 88 49 108
9 19 23 88 32 153 8 65 19 125 24 134 19 36 51 53 127 69 163 33 244 103 244
212 0 35 -8 46 -92 127 -134 129 -788 701 -788 689z"
        />
        <path
          d="M1049 2715 c88 -20 175 -63 246 -120 77 -63 94 -81 233 -255 64 -79
142 -169 173 -201 114 -111 266 -161 547 -180 201 -13 266 -32 334 -100 26
-26 48 -42 48 -35 0 48 -78 207 -142 289 -34 42 -48 47 -48 15 0 -35 -45 -122
-74 -145 -31 -25 -61 -20 -83 13 -14 22 -14 28 6 78 25 60 27 149 4 204 -11
28 -29 40 -106 77 -136 65 -227 86 -387 92 -74 3 -162 0 -194 -5 l-59 -9 -51
54 c-55 60 -149 137 -207 170 -61 35 -161 64 -234 69 l-70 4 64 -15z"
        />
        <path
          d="M963 2634 c-19 -50 21 -233 73 -335 33 -66 129 -163 186 -189 47 -21
108 -26 108 -9 0 6 -18 41 -40 79 -24 40 -46 96 -55 137 -35 162 -54 210 -102
254 -33 31 -74 53 -126 67 -32 9 -39 8 -44 -4z"
        />
        <path
          d="M2342 2585 c-7 -25 -12 -64 -12 -88 0 -68 -7 -72 -85 -42 -89 33
-103 27 -29 -12 66 -35 118 -84 133 -126 6 -16 11 -74 12 -130 0 -97 1 -100
14 -67 7 19 14 63 14 97 2 107 17 116 90 55 76 -64 80 -59 10 14 -102 108
-117 131 -129 207 -6 38 -8 84 -4 103 9 49 0 42 -14 -11z"
        />
        <path
          d="M1763 1908 c7 -91 31 -148 91 -215 72 -80 161 -131 411 -238 257
-109 361 -167 416 -234 31 -38 69 -126 70 -164 1 -50 51 91 62 176 23 181 -26
315 -163 444 -75 71 -273 146 -445 168 -203 26 -341 64 -402 111 -17 13 -35
24 -39 24 -4 0 -4 -33 -1 -72z"
        />
        <path
          d="M1123 1820 c-74 -45 -92 -152 -34 -197 38 -29 176 -3 226 43 23 21
27 31 22 61 -15 91 -133 143 -214 93z"
        />
        <path
          d="M1980 1454 c6 -14 10 -64 10 -112 0 -78 -3 -92 -30 -139 -30 -51 -90
-108 -145 -136 -31 -15 -124 -37 -275 -63 -96 -16 -188 -45 -240 -75 -29 -16
-31 -19 -10 -15 82 16 240 35 331 40 203 11 332 76 396 200 23 43 27 64 27
131 1 69 -3 88 -29 138 -29 58 -53 79 -35 31z"
        />
        <path
          d="M2114 1374 c-12 -63 2 -165 31 -234 15 -36 70 -130 123 -210 174
-264 207 -348 185 -475 -6 -35 -18 -78 -26 -94 -18 -37 -18 -37 34 -11 57 29
149 127 187 197 81 152 83 354 4 493 -54 97 -155 178 -347 280 -55 29 -113 62
-130 72 -43 26 -53 23 -61 -18z"
        />
        <path
          d="M1195 770 c-38 -11 -87 -27 -110 -36 -95 -41 -285 -163 -285 -183 0
-4 33 -13 73 -20 108 -18 178 -58 286 -160 127 -122 196 -145 290 -98 69 34
100 116 71 191 -24 63 -138 89 -185 41 -16 -15 -24 -34 -24 -52 l2 -28 4 26
c13 82 143 82 174 1 14 -37 -4 -95 -38 -121 -75 -60 -216 16 -216 116 0 87 65
143 165 143 120 -1 192 -65 283 -253 73 -150 107 -195 173 -235 62 -36 153
-37 226 -4 27 12 47 24 44 27 -2 2 -30 0 -62 -5 -160 -26 -234 47 -266 260
-22 149 -51 221 -119 293 -97 104 -304 145 -486 97z"
        />
        <path
          d="M2145 140 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
      </g>
    </svg>
  );
});

export default UniswapIcon;
