import styled from "styled-components";
import {desktopDefaultWidthPx, mobileMaxWidthPx} from "../../../../config/consts";
import {theme} from "../../../../config/theme";

export const bioShapeSizeLarge = 900;
export const bioShapeSizeDefault = 700;
const bioShapeSizeMobile = 400;

export const BioShapeItemWrapper = styled.div`
  position: relative;
  max-width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  grid-column: span 2;
  -webkit-tap-highlight-color: ${theme.colors.transparent};
  width: ${bioShapeSizeDefault}px;

  @media (min-width: ${desktopDefaultWidthPx}) {
    width: ${bioShapeSizeLarge}px;
  }
`;

export const BioShapeItemSvgWrapper = styled.svg`
  position: relative;
  display: block;
  width: 80%;
  height: auto;
  margin: 0 auto;
  min-height: ${bioShapeSizeDefault * 0.85}px;

  @media (max-width: ${mobileMaxWidthPx}) {
    min-height: ${bioShapeSizeMobile}px;
  }

  @media (min-width: ${desktopDefaultWidthPx}) {
    min-height: ${bioShapeSizeLarge * 0.85}px;
  }
`;

export const BioShapeItemShapePathWrapper = styled.path`
  transform-origin: 50% 50%;
`;

export const BioShapeItemDecoWrapper = styled.g`
  transform-origin: 50% 50%;
  fill: ${theme.colors.tiffany};
`;

export const BioShapeItemImageWrapper = styled.image`
  transform-origin: 50% 50%;
`;

export const BioShapeItemMetaWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 15%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 70%;
  height: 100%;
`;

export const BioShapeItemTitleBlockWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: -40px;
  width: 100%;
  letter-spacing: 0.5em;
  text-transform: uppercase;

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 0.6em;
    left: -20px;
  }
`;

export const BioShapeItemTitleWrapper = styled.span`
  font-size: 2.5em;
  line-height: 1.2;
  vertical-align: text-bottom;
  letter-spacing: -0.025em;
  font-weight: 600;
  font-family: ${theme.fonts.roboto.black};
  color: ${theme.colors.white};
  text-shadow: 2.5px 2.5px 1.5px ${theme.colors.translucent.black(0.9)};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 2.1em;
  }
`;

export const BioShapeItemDescriptionWrapper = styled.h2`
  font-size: 1.8em;
  width: 100%;
  margin: 1em 0 0 0;
  text-align: center;
  letter-spacing: 0.1em;
  color: ${theme.colors.translucent.black(0.9)};
  font-family: ${theme.fonts.roboto.black};
  font-weight: 900;
  text-shadow: 1.5px 1.5px 1px ${theme.colors.translucent.white(0.9)};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 1.25em;
  }
`;

export const BioShapeItemExtraDescriptionWrapper = styled.h2`
  font-size: 1.6em;
  margin: 0.5em 0 0;
  text-align: center;
  letter-spacing: 0.1em;
  opacity: 0;
  color: ${theme.colors.translucent.black(0.9)};
  font-family: ${theme.fonts.roboto.black};
  font-weight: 900;
  text-shadow: 1.5px 1.5px 1px ${theme.colors.translucent.white(0.9)};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: 1em;
  }
`;
