import {FC, memo, useCallback, useState} from "react";
import {useMediaQuery} from "react-responsive";
import styled, {css} from "styled-components";
import {defaultTransition, mobileMaxWidth, mobileMaxWidthPx} from "../../config/consts";
import {theme} from "../../config/theme";
import LinkIcon from "../Icons/Link";
import {ILinkBackgroundProps, ILinkProps} from "./types";

const Link: FC<ILinkProps> = memo((props) => {
  const {children, scrollToId, href, color, fontFamily} = props;

  const [isHovered, setIsHovered] = useState(false);

  const isMobile = useMediaQuery({maxWidth: mobileMaxWidth});

  const handleClick = useCallback(() => {
    if (scrollToId) {
      const element = document.getElementById(scrollToId);
      element.scrollIntoView({behavior: "smooth"});
    }
    if (href) {
      window.open(href);
    }
  }, [href, scrollToId]);

  return (
    <LinkWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => handleClick()}
    >
      <LinkTextWrapper isHovered={isHovered}>
        <LinkBackground isHovered={isHovered} />
        <ChildWrapper color={color} fontFamily={fontFamily}>
          {children}
        </ChildWrapper>
      </LinkTextWrapper>
      <LinkIconWrapper>
        <LinkIcon isHovered={isHovered || isMobile} />
      </LinkIconWrapper>
    </LinkWrapper>
  );
});

const LinkWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  font-size: ${theme.fonts.sizes.xxlg};

  @media (max-width: ${mobileMaxWidthPx}) {
    font-size: ${theme.fonts.sizes.lg};
  }
`;

const LinkBackground = styled.div<ILinkBackgroundProps>`
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  border-radius: ${theme.borderRadius.xs};
  background: ${theme.colors.tiffany};
  transition: width ${defaultTransition};
  width: ${({isHovered}) => (isHovered ? "100%" : "0")};

  @media (max-width: ${mobileMaxWidthPx}) {
    width: ${({isHovered}) => (isHovered ? "100%" : "0")};
  }
`;

const LinkTextWrapper = styled.div<{isHovered: boolean}>`
  position: relative;
  padding: 3px 10px;
  transition: color ${defaultTransition};
  color: ${({isHovered}) =>
    isHovered
      ? `${theme.colors.translucent.black(0.85)}`
      : `${theme.colors.translucent.white(0.85)}`};
`;

const LinkIconWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;

  @media (max-width: ${mobileMaxWidthPx}) {
    width: 17px;
    height: 17px;
  }
`;

const ChildWrapper = styled.div<{color: string; fontFamily: string}>`
  position: relative;
  z-index: 1;
  color: ${({color}) => color || "unset"};
  ${({fontFamily}) =>
    fontFamily &&
    css`
      font-family: ${fontFamily};
    `}
`;

export default Link;
