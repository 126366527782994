import {memo} from "react";
import {BioShapeTextWrapper, BoldTextWrapper} from "../index";

const BioShape4Text = memo(() => {
  return (
    <BioShapeTextWrapper>
      <div>Revenue share system in the Pretorian Ecosystem:</div>
      <div>
        1. <BoldTextWrapper>Incubation Platform:</BoldTextWrapper> Pretorian Ecosystem acts as an
        incubation platform where developers bring their projects to be developed. This implies that
        developers seek your expertise and resources to bring their ideas to life.
      </div>
      <div>
        2. <BoldTextWrapper>Payment Options:</BoldTextWrapper> Developers are presented with a
        unique choice when it comes to payment for the services rendered. They can pay in
        stablecoins, which are then utilized for buybacks. These buybacks have the potential to
        enhance the value of the native token, $PRET, through decreasing its supply or increasing
        demand. Alternatively, developers have the option to offer their token supply, which will be
        distributed in a predetermined manner among the holders of $PRET
      </div>
      <div>
        3. <BoldTextWrapper>Benefit Generation:</BoldTextWrapper> Through this revenue share system,
        the ecosystem creates a mutually beneficial cycle. Developers receive top-notch development
        services while having flexible payment options. Simultaneously, $PRET holders stand to
        benefit from potential token price appreciation due to the buybacks and additional token
        distributions, fostering a symbiotic relationship within the ecosystem.
      </div>
      <div>
        4. <BoldTextWrapper>Value Appreciation:</BoldTextWrapper> The revenue generated through
        these mechanisms not only compensates for the services provided but also contributes to the
        ecosystem&apos;s growth and value appreciation. By intertwining the value of $PRET with the
        projects incubated within the ecosystem, a dynamic and interconnected economic model is
        established, aligning the incentives of developers, $PRET holders, and the ecosystem.
      </div>
    </BioShapeTextWrapper>
  );
});

export default BioShape4Text;
