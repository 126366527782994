import {css} from "styled-components";
import {mobileMaxWidthPx} from "../config/consts";
import {theme} from "../config/theme";

export default css`
  .highcharts-text-outline {
    fill: none;
    stroke: none;
  }

  .highcharts-credits {
    display: none;
  }

  .highcharts-title {
    font-size: 1.5em !important;

    @media (max-width: ${mobileMaxWidthPx}) {
      font-size: 1.3em !important;
    }
  }

  .highcharts-label {
    text {
      color: ${theme.colors.white} !important;
      fill: ${theme.colors.white} !important;
      font-size: 1.25em !important;

      @media (max-width: ${mobileMaxWidthPx}) {
        font-size: 1em !important;
      }
    }
  }
`;
