import {css} from "styled-components";
import {theme} from "../config/theme";

export default css`
  * {
    font-family: ${theme.fonts.roboto.regular};
  }

  *::selection {
    background: ${theme.colors.translucent.white(0.2)};
    color: ${theme.colors.tiffany};
  }

  html,
  body,
  #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;
