import {css} from "styled-components";
import {defaultTransition} from "../config/consts";
import {theme} from "../config/theme";

export default css`
  .accordion-item {
    border: 1px solid ${theme.colors.black};
  }

  .accordion-item-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 20px;
    font-size: 1rem;
    text-align: left;
    background-color: transparent;
    border: none;
    transition: background ${defaultTransition};
    color: ${theme.colors.black};
  }

  .accordion-item-button:hover {
    background-color: ${theme.colors.semiLightGrey};
  }

  .accordion-item-button-expanded {
    background-color: ${theme.colors.grey};

    svg {
      rotate: 180deg;
    }
  }

  .accordion-item-content {
    transition: height ${defaultTransition};
  }

  .accordion-item-panel {
    padding: 20px;
  }

  .accordion-item-button-expanded .chevron {
    transform: rotate(180deg);
  }
`;
