import {memo} from "react";
import {useMediaQuery} from "react-responsive";
import {toast} from "react-toastify";
import styled from "styled-components";
import Icon from "../../../components/Icon";
import LogoIcon from "../../../components/Icons/LogoIcon";
import DexscreenerIcon from "../../../components/Icons/Social/DexscreenerIcon";
import DextoolsIcon from "../../../components/Icons/Social/DextoolsIcon";
import GitBookIcon from "../../../components/Icons/Social/GitBookIcon";
import TelegramIcon from "../../../components/Icons/Social/TelegramIcon";
import TwitterIcon from "../../../components/Icons/Social/TwitterIcon";
import UniswapIcon from "../../../components/Icons/Social/UniswapIcon";
import Link from "../../../components/Link";
import {defaultTransition, mobileMaxWidth, mobileMaxWidthPx} from "../../../config/consts";
import {theme} from "../../../config/theme";
import {socialIconTheme} from "./consts";

const HeaderBlock = memo(() => {
  const isMobile = useMediaQuery({maxWidth: mobileMaxWidth});

  return (
    <HeaderBlockWrapper
      className={"header-block_header-block-wrapper"}
      justifyContent={isMobile ? "center" : "space-between"}
      flexDirection={isMobile ? "column" : "row"}
    >
      <IcoLogoWrapper>
        <Icon
          icon={LogoIcon}
          iconTheme={{
            size: isMobile ? "65px" : "160px",
            color: theme.colors.tiffany,
            hoverColor: theme.colors.translucent.white(0.85),
          }}
        />
      </IcoLogoWrapper>
      <LinksAndTextWrapper marginLeft={isMobile ? 0 : 30}>
        <LinksWrapper>
          <Link href="" scrollToId="about-block_about-block-wrapper">
            Key Features
          </Link>
          <Link href="" scrollToId={"roadmap-block_roadmap-block-wrapper"}>
            Pretorian Premium
          </Link>
          <Link href="" scrollToId="tokenomic-block_tokenomic-block-wrapper">
            Tokenomics
          </Link>
          <Link href="" scrollToId="footer-block_footer-block-wrapper">
            Our Projects
          </Link>
          <div onClick={() => toast.info("Coming soon.")}>
            <Link href="">KYC</Link>
          </div>
          <div onClick={() => toast.info("Coming soon.")}>
            <Link href="">Audit</Link>
          </div>
        </LinksWrapper>
        <TextWrapper display={isMobile ? "none" : "block"}>
          Solution for developing YOUR project
        </TextWrapper>
      </LinksAndTextWrapper>
      <SocialWrapper>
        <Icon
          icon={TwitterIcon}
          iconTheme={{...socialIconTheme, padding: "11px", size: isMobile ? "21px" : "28px"}}
          onClick={() => window.open("https://x.com/pretorianai?s=21")}
        />
        <Icon
          icon={TelegramIcon}
          iconTheme={{...socialIconTheme, padding: "8px", size: isMobile ? "27px" : "34px"}}
          onClick={() => window.open("https://t.me/+YFgBwmfrvuliMWEy")}
        />
        <Icon
          icon={GitBookIcon}
          iconTheme={{...socialIconTheme, padding: "5px", size: isMobile ? "33px" : "40px"}}
          onClick={() => window.open("https://whitepaper.pretorianecosystem.nl/pretorianbotai")}
        />
        <Icon
          icon={DextoolsIcon}
          iconTheme={{...socialIconTheme, padding: "8px", size: isMobile ? "27px" : "34px"}}
          onClick={() =>
            window.open(
              "https://www.dextools.io/app/en/ether/pair-explorer/0x2f1551b2081acbec194bf9ea0461c40c58d5227a",
            )
          }
        />
        <Icon
          icon={DexscreenerIcon}
          iconTheme={{...socialIconTheme, padding: "8px", size: isMobile ? "27px" : "34px"}}
          onClick={() =>
            window.open(
              "https://dexscreener.com/ethereum/0x1839CAd2F9B2bE10dF5A8F5b2F22cA1C2E9c8123",
            )
          }
        />
        <Icon
          icon={UniswapIcon}
          iconTheme={{...socialIconTheme, padding: "6px", size: isMobile ? "31px" : "38px"}}
          onClick={() =>
            window.open(
              "https://app.uniswap.org/swap?inputCurrency=0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2&outputCurrency=0x1839CAd2F9B2bE10dF5A8F5b2F22cA1C2E9c8123&chain=mainnet",
            )
          }
        />
      </SocialWrapper>
    </HeaderBlockWrapper>
  );
});

const HeaderBlockWrapper = styled.div<{justifyContent: string; flexDirection: string}>`
  display: flex;
  padding: 20px 30px;
  opacity: 0;
  align-items: center;
  background: ${theme.colors.darkGrey};
  justify-content: ${({justifyContent}) => justifyContent};
  flex-direction: ${({flexDirection}) => flexDirection};

  @media (max-width: ${mobileMaxWidthPx}) {
    gap: 30px;
    padding: 20px 15px;
  }
`;

const IcoLogoWrapper = styled.div`
  transition: transform ${defaultTransition};

  :hover {
    transform: rotate(25deg) scale(1.1);
  }
`;

const LinksAndTextWrapper = styled.div<{marginLeft: number}>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 30px;
  margin-left: ${({marginLeft}) => marginLeft}px;
`;

const SocialWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

const TextWrapper = styled.div<{display: string}>`
  font-size: 25px;
  color: ${theme.colors.translucent.white(0.85)};
  display: ${({display}) => display};
`;

export default HeaderBlock;
