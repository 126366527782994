import {css} from "styled-components";
import {theme} from "../config/theme";

export default css`
  .toast-common {
    background: #2a2a2a;
    box-shadow: 2px 2px 4px ${theme.colors.tiffany};
    font-size: ${theme.fonts.sizes.md};
  }

  .toast-common.Toastify__toast--info .Toastify__toast-icon svg {
    fill: ${theme.colors.white};
  }

  .Toastify__progress-bar--info {
    background: ${theme.colors.tiffany};
  }
`;
