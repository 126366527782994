import {memo} from "react";
import {BioShapeTextWrapper, BoldTextWrapper, ListElementWrapper} from "../index";

const BioShape2Text = memo(() => {
  return (
    <BioShapeTextWrapper>
      <div>
        Our <BoldTextWrapper>team with the help of our bot</BoldTextWrapper> enhances smart contract
        management by embedding robust safety features:
      </div>
      <ListElementWrapper>1. Smart contract creation & deployment</ListElementWrapper>
      <ListElementWrapper>
        2. <BoldTextWrapper>Immediate Contract Renunciation</BoldTextWrapper> – Ensuring the
        contract cannot be altered post-deployment.
      </ListElementWrapper>
      <ListElementWrapper>
        3. <BoldTextWrapper>Contract Safety Verification</BoldTextWrapper> – Comprehensive audits to
        guarantee contract integrity.
      </ListElementWrapper>
      <ListElementWrapper>
        4. <BoldTextWrapper>Automated Tax Adjustment</BoldTextWrapper> – Streamlining tax reduction
        without manual intervention.
      </ListElementWrapper>
      <ListElementWrapper>
        5. <BoldTextWrapper>Efficient Whitelist Administration</BoldTextWrapper> – Facilitating a
        smooth process for whitelist operations.
      </ListElementWrapper>
    </BioShapeTextWrapper>
  );
});

export default BioShape2Text;
