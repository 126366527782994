import ReactDOM from "react-dom/client";
import App from "./App";
import {history} from "./routes/history";
import {Router} from "react-router-dom";
import GlobalStyle from "./styles/globalStyle";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router history={history}>
    <GlobalStyle />
    <App />
  </Router>,
);
